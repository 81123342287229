import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { motion, useTransform, useViewportScroll } from "framer-motion";
// import { easing } from "../../../../util/math";
import { useGetResizer } from "../../../../contexts/Resizer";
// import { useInView } from "react-intersection-observer";
import Picket from "./Picket";
import { groupBy, orderBy } from "lodash";
import { DateTime } from "luxon";
// import littlePerson from "../../../../images/little-person-dice.png";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import { screen } from "../../../../styles/mixins";
import { transparentize } from "polished";
import { colours } from "../../../../styles/colours";

const Container = styled.div`
	height: 900vh;
	width: 100%;
`;

const Inner = styled.div`
	position: sticky;
	top: 0;
	width: 100%;
	height: 100vh;
`;

const VisualisationWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ProtestPickets = styled.div`
	height: 100%;
	width: 50%;
	transform: translateX(50%) scale(0.6) translateY(25%);
	z-index: 2;
	position: relative;
	perspective: 1200px;
	perspective-origin: center calc(100% - 100px);
	transform-style: preserve-3d;

	@media (orientation: landscape) {
		transform: translateX(50%) scale(0.4) translateY(90%);
	}

	@media ${screen("md")} and (min-height: 450px) {
		width: 100%;
		transform: none;
	}
`;

const FogTop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 15vh;
	overflow: hidden;
	pointer-events: none;
	background: linear-gradient(
		0deg,
		${`${transparentize(1, colours.bgWhite)} 0%`},
		${`${transparentize(0, colours.bgWhite)} 100%`}
	);
	/* transform: translateZ(50000px); */
	z-index: 1;
`;

const FogBottom = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50vh;
	overflow: hidden;
	pointer-events: none;
	transform: translateZ(50000px);
	z-index: 3;

	&::after {
		content: "";
		position: absolute;
		bottom: -10%;
		left: -50%;
		width: 200%;
		height: 200%;
		/* transform: scaleY(5%); */
		background: radial-gradient(
			ellipse at center,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 1) 60%
		);
	}
`;

const PerspectiveContainer = styled(motion.div)`
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transform-origin: bottom center;
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translateZ(-9305px);
`;

const BackgroundImage = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

// const Floor = styled.div`
// 	width: 10000px;
// 	height: 10000px;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translateX(-50%) translateY(-50%) translateZ(20px);
// 	opacity: 0.8;
// 	background-color: #cccccc;
// `;
// const BackWall = styled.div`
// 	position: absolute;
// 	background-color: #eeeeee;
// 	top: 0;
// 	width: 100000px;
// 	height: 15000px;
// 	left: 50%;
// 	transform: translateX(-50%) translateY(-2300px) rotateX(90deg);
// 	transform-origin: top center;
// `;

// const LittlePerson = styled.img`
// 	position: absolute;
// 	transform: rotateX(-90deg) translateY(60%) translateZ(-500px);
// 	transform-origin: bottom center;
// 	left: 45%;

// 	width: 300px;
// 	height: auto;
// `;

function transformData(data) {
	let expandedProtests = [];
	for (const protest of data) {
		let start = DateTime.fromISO(protest.Start);
		let end = DateTime.fromISO(
			protest.End === "present" ? "2021-05-24" : protest.End
		);
		let duration = Math.floor(end.diff(start, "years").years);
		duration = duration || 1;

		// for (let i = 0; i < duration; i++) {
		// }

		const i = 0;

		let adjustedStart = start.plus({ years: i });
		let adjustedEnd = start.plus({ years: i });
		expandedProtests.push({
			protest: protest.Movement_Protest,
			country: protest.Country,
			start: adjustedStart,
			end: adjustedEnd,
			duration: duration,
			startYear: start?.year,
			endYear: end?.year,
		});
	}
	expandedProtests = orderBy(
		expandedProtests,
		[(d) => d.start, (d) => d.end],
		["asc", "asc"]
	);
	let protestsByYear = groupBy(expandedProtests, (d) => d.start.year);

	// let max = 2020;
	// let min = 2008;
	// let totalYears = max - min;
	// let duration = totalYears * 12;

	let positions = [
		"26%",
		"58%",
		"42%",
		"38%",
		"62%",
		"72%",
		"39%",
		"23%",
		"39%",
		"59%",
		"41%",
		"45%",
	];
	let sizes = ["wide", "wide", "tall", "wide", "tall", "tall", "tall", "wide"];
	// > 50 - < 50 +
	let rotations = [10, -20, 18, 25, -20, -15, 24, 10, 12, -18, 0, -12];
	let positionCounter = 0;
	let rotationCounter = 0;
	let sizesCounter = 0;
	let position = 0;
	let durationOnScreen = 12;

	let pickets = [];
	for (const year of Object.keys(protestsByYear)) {
		let protests = protestsByYear[year];
		// let yearPosition = "50%";
		// if (year === "2008") {
		// 	yearPosition = "25%";
		// } else if (year === "2009") {
		// 	yearPosition = "60%";
		// }
		// let picket = {
		// 	zRange: [position, position + durationOnScreen],
		// 	opacityRange: [
		// 		position,
		// 		position + 1,
		// 		position + durationOnScreen - 3,
		// 		position + durationOnScreen,
		// 	],
		// 	protest: year,
		// 	yearPicket: true,
		// 	position: yearPosition,
		// 	rotation: 0,
		// 	size: "year",
		// };
		// pickets.push(picket);
		// position += 2;
		for (const protest of protests) {
			let picket = {
				zRange: [position, position + durationOnScreen],
				opacityRange: [
					position,
					position + 1,
					position + durationOnScreen - 0.5,
					position + durationOnScreen,
				],
				country: protest.country,
				protest: protest.protest,
				start: protest.start,
				end: protest.end,
				// year: protest.year,
				position: positions[positionCounter],
				rotation: rotations[rotationCounter],
				size: sizes[sizesCounter],
				yearRange: `${protest.startYear}${
					protest.endYear && protest.endYear !== protest.startYear
						? ` - ${protest.endYear}`
						: ""
				}`,
			};
			pickets.push(picket);
			position++;

			positionCounter++;
			if (positionCounter === positions.length) {
				positionCounter = 0;
			}
			rotationCounter++;
			if (rotationCounter === rotations.length) {
				rotationCounter = 0;
			}
			sizesCounter++;
			if (sizesCounter === sizes.length) {
				sizesCounter = 0;
			}
		}
		// position += 4;
	}
	return { pickets, position: position - 1 + durationOnScreen };
}

export default function Scene({
	data: { childrenGlobalProtestsCsv: data },
	title,
}) {
	let { pickets: protests, position } = useMemo(() => transformData(data), [
		data,
	]);
	const area = useGetResizer();
	const containerRef = useRef();
	const [containerBounds, setContainerBounds] = useState({
		top: 0,
		bottom: 100,
	});
	const viewportScroll = useViewportScroll();
	useEffect(() => {
		let element = containerRef.current;
		let scrollY = window.scrollY;
		let rect = element.getBoundingClientRect();
		setContainerBounds({
			top: rect.top + scrollY,
			bottom: rect.bottom + scrollY - window.innerHeight,
		});
	}, [area]);
	const progress = useTransform(
		viewportScroll.scrollY,
		[containerBounds.top, containerBounds.bottom],
		[0, position]
	);

	// const cameraRotation = useTransform(progress, [0, 35], [94, 79], {
	// 	ease: easing.outQuad,
	// });
	// const cameraZ = useTransform(progress, [0, 35], [0, -660], {
	// 	ease: easing.outQuad,
	// });
	// const cameraY = useTransform(progress, [0, 25], ["-26.17%", "0%"], {
	// 	ease: easing.outQuad,
	// });

	return (
		<Container ref={containerRef}>
			{/* <Title title={title} containerTop={containerBounds.top} /> */}
			<Inner>
				<VisualisationWrapper>
					<FogTop />
					<Background>
						<StaticImage
							css={BackgroundImage}
							src={"../../../../images/pickets-bg-plate.png"}
							alt={"Background clouds"}
							layout="fullWidth"
							sizes="(min-width: 1530px) 1530px, 200vw"
							placeholder="none"
							objectPosition="bottom center"
						/>
					</Background>
					<ProtestPickets>
						<PerspectiveContainer style={{ rotateX: 87 }}>
							{/* <Floor /> */}
							{/*<BackWall />*/}
							{/* <LittlePerson src={littlePerson} /> */}
							{protests.map((p, i) => {
								return (
									<Picket
										key={i}
										zIndex={200 - i}
										progress={progress}
										zProgress={p.zRange}
										zValues={[-9300, 1200]}
										opacityProgress={p.opacityRange}
										opacityValues={[0, 1, 1, 0]}
										left={p.position}
										size={p.size}
										yearPicket={!!p.yearPicket}
										rotate={p.rotation}>
										{p.yearRange}
										{p.yearRange ? (
											<>
												<br />
											</>
										) : (
											""
										)}
										{p.protest} {p.country ? "in" : ""} {p.country}
									</Picket>
								);
							})}
						</PerspectiveContainer>
					</ProtestPickets>
					<FogBottom />
				</VisualisationWrapper>
			</Inner>
		</Container>
	);
}
