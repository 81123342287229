import React, { memo, useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
	AnimatedAxis,
	XYChart,
	LineSeries,
	AnimatedGrid,
	Axis,
} from "@visx/xychart";
import { curveNatural } from "@visx/curve";
import { format } from "d3-format";
import { extent } from "d3-array";
import { useGetResizer } from "../../../../contexts/Resizer";
import { motion, useTransform } from "framer-motion";
import { easing } from "../../../../util/math";
import { fontSurt } from "../../../../styles/typography";
import { AgeContext } from "./AgeContext";

const Container = styled.div`
	grid-column: 2 / 30;
	grid-row: 2 / 3;
	padding-bottom: 30px;
	overflow: hidden;
	.visx-axis-tick {
		text {
			font-size: 12px;
			font-weight: 400;
			fill: black;
			${fontSurt};
		}
	}
	.visx-axis-label {
		font-size: 12px;
		${fontSurt};
		font-weight: 600;
	}
	svg {
		height: 100%;
		width: 100%;
	}
`;
const tickLabelOffset = 5;
let formatter = format("~s");

const GraphInner = memo(function ({
	game,
	age,
	otherGames,
	additionalGamesInput,
	max,
}) {
	const domain = [-max, max];
	const [clipBounds, setClipBounds] = useState({
		width: 600,
		height: 400,
		x: 0,
		y: 0,
	});
	const chartRef = useRef();
	const area = useGetResizer();
	const [containerRect, setContainerRect] = useState({
		width: 600,
		height: 400,
	});

	useEffect(() => {
		let element = chartRef.current;
		let rect = element.getBoundingClientRect();
		setClipBounds({
			x: 65,
			y: 10,
			width: rect.width - 85 - 27,
			height: rect.height - 10 - 85,
		});
		setContainerRect({ width: rect.width, height: rect.height });
	}, [area]);

	let clipLocation = useTransform(age, [18, 65], [-clipBounds.width, 0]);
	let otherGamesClip = useTransform(
		age,
		additionalGamesInput,
		[-clipBounds.width, 0],
		{
			ease: easing.inOutQuad,
		}
	);

	return (
		<Container ref={chartRef}>
			<XYChart
				width={containerRect.width}
				height={containerRect.height}
				margin={{ left: 85, top: 10, bottom: 85, right: 27 }}
				xScale={{ type: "point", domain: game.game.map((s) => s.age) }}
				yScale={{ type: "linear", domain: domain }}>
				<defs>
					<clipPath id={"chartClip"}>
						<motion.rect
							x={clipBounds.x}
							y={clipBounds.y}
							width={clipBounds.width}
							height={clipBounds.height}
							style={{ translateX: clipLocation }}
						/>
					</clipPath>
					<clipPath id={"otherGamesClip"}>
						<motion.rect
							x={clipBounds.x}
							y={clipBounds.y}
							width={clipBounds.width}
							height={clipBounds.height}
							style={{ translateX: otherGamesClip }}
						/>
					</clipPath>
				</defs>
				<AnimatedGrid
					columns={true}
					numTicks={4}
					lineStyle={{
						stroke: "#767676",
						strokeLinecap: "round",
						strokeWidth: 1,
					}}
					strokeDasharray="0, 4"
				/>
				<Axis
					hideTicks
					orientation="bottom"
					tickLabelProps={() => ({ dy: tickLabelOffset })}
					label={"AGE"}
					tickValues={[18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]}
					labelOffset={25}
					axisClassName={"age-axis"}
				/>
				<AnimatedAxis
					hideAxisLine
					hideTicks
					orientation="left"
					label={"YOUR WELATH OVER TIME (DOLLARS)"}
					tickFormat={formatter}
					labelOffset={45}
					// numTicks={6}
					tickLabelProps={() => ({ dx: -10 })}
					axisClassName={"wealth-axis"}
				/>
				{otherGames.map((game, i) => {
					return (
						<LineSeries
							key={i}
							stroke={"#888888"}
							dataKey={`otherGames${i}`}
							data={game}
							yAccessor={(d) => d.state.wealth}
							xAccessor={(d) => d.state.age}
							clipPath={"url(#otherGamesClip)"}
							curve={curveNatural}
						/>
					);
				})}

				<LineSeries
					stroke={"#414BB2"}
					strokeWidth={3}
					dataKey={"wealth"}
					data={game.game}
					yAccessor={(d) => d.wealth}
					xAccessor={(d) => d.age}
					clipPath={"url(#chartClip)"}
					curve={curveNatural}
				/>
			</XYChart>
		</Container>
	);
});

export default function Graph({ game, otherGames, age, additionalGamesInput }) {
	const currentAge = useContext(AgeContext);
	const [max, setMax] = useState(50000);
	useEffect(() => {
		if (currentAge <= 65 && currentAge >= 16) {
			let domain = extent(
				game.game.slice(0, Math.max(1, currentAge - 14)),
				(d) => d.wealth
			);
			setMax((max) => {
				let newMax = Math.max(Math.abs(domain[0]), Math.abs(domain[1]));
				if (newMax >= max) {
					let m = max * 3;
					while (newMax > m) {
						m = m * 3;
					}
					return m;
				} else if (newMax < max / 3) {
					let m = max / 3;
					while (newMax < m) {
						m = m / 3;
					}
					m = m * 3;
					return m < 50000 ? 50000 : m;
				}
				return max;
			});
		} else if (currentAge > 65 && currentAge <= 75) {
			let allDomains = [];
			let domain = extent(game.game, (d) => d.wealth);
			allDomains.push(...domain);
			otherGames.forEach((game) => {
				let domain = extent(game, (d) => d.state.wealth);
				allDomains.push(...domain);
			});
			let maxDomain = extent(allDomains);
			setMax(Math.max(Math.abs(maxDomain[0]), Math.abs(maxDomain[1])));
		}
	}, [currentAge, setMax, game, otherGames]);

	return (
		<GraphInner
			{...{ game, otherGames, age, additionalGamesInput }}
			max={max}
		/>
	);
}
