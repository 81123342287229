import React, { useEffect, useRef, useState } from "react";
import { SceneManager } from "./WebglDice/SceneManager";
import styled from "@emotion/styled";
import { useTransform, useViewportScroll } from "framer-motion";
import { useGetResizer } from "../../../contexts/Resizer";
const Container = styled.div`
	height: 400vh;
	position: relative;
`;
const Inner = styled.div`
	position: sticky;
	top: 0;
	background-color: white;
	height: 100vh;
	width: 100%;
`;
const Canvas = styled.canvas`
	width: 100%;
	height: 100%;
	background-color: white;
`;
export default function WebglDice() {
	const ref = useRef();
	const containerRef = useRef();
	const area = useGetResizer();
	const [containerBounds, setContainerBounds] = useState({
		top: 0,
		bottom: 100,
	});

	const viewportScroll = useViewportScroll();
	useEffect(() => {
		let element = containerRef.current;
		let scrollY = window.scrollY;
		let rect = element.getBoundingClientRect();

		setContainerBounds({
			top: rect.top + scrollY,
			bottom: rect.bottom + scrollY - window.innerHeight / 2,
		});
	}, [area]);
	const progress = useTransform(
		viewportScroll.scrollY,
		[containerBounds.top, containerBounds.bottom],
		[0, 1.2]
	);
	useEffect(() => {
		let scene = new SceneManager(ref.current, progress);
		return () => {
			scene.cleanup();
			scene = null;
		};
	}, [progress]);
	return (
		<Container ref={containerRef}>
			<Inner>
				<Canvas ref={ref} />
			</Inner>
		</Container>
	);
}
