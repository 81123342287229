import React from "react";
import styled from "@emotion/styled";
import { screen } from "../../../../styles/mixins";
import { StaticImage } from "gatsby-plugin-image";

const Visualisation = styled.div`
	padding-bottom: 90%;
	width: 100%;
	position: relative;

	@media ${screen("md")} {
		padding-bottom: 28%;
	}
`;

const HeightWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-bottom: 114%;
	overflow: hidden;
`;

const ImageWrapper = styled.div`
	position: absolute;
	top: 4%;
	right: -17%;
	width: 100%;
	height: 100%;

	@media ${screen("md")} {
		width: 86%;
	}
`;

export default function DominosMiddle() {
	return (
		<Visualisation>
			<HeightWrapper>
				<ImageWrapper>
					<StaticImage
						src="../../../../images/domino-man.jpg"
						alt="Dominos falling"
						placeholder="none"
						layout="fullWidth"
						aspectRatio={3248 / 2771}
						objectFit="contain"
						objectPosition="center top"
					/>
				</ImageWrapper>
			</HeightWrapper>
		</Visualisation>
	);
}
