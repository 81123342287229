import React from "react";
import styled from "@emotion/styled";
import DominosTop from "../../Feature/ActOne/RadicalDecline/DominosTop";
import DominosBottom from "../../Feature/ActOne/RadicalDecline/DominosBottom";
import { richText } from "../../../styles/typography";
import { grid, spacing, screen } from "../../../styles/mixins";
import DominosMiddle from "./RadicalDecline/DominosMiddle";

const Container = styled.div`
	${spacing("marginBottom", "xl", {
		mn: {
			base: -8,
			ratio: 1.5,
		},
		md: {
			base: -3,
			ratio: 2,
		},
		ms: {
			base: -4,
			ratio: 2,
		},
	})}
`;

const DominosTopWrapper = styled.div``;

const DominosBottomWrapper = styled.div`
	${spacing("marginTop", "s")};
	position: relative;
	z-index: -1;
`;

const Part = styled.div`
	${spacing("marginBottom", "l")};
	position: relative;
`;

const Copy = styled.div`
	${grid};
	grid-area: copy;

	@media ${screen("ml")} {
		${grid};
	}
`;

const Content = styled.div`
	${richText};

	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: ${({ partIndex }) =>
			partIndex === 0 ? "6 / -14" : "14 / -6"};
	}

	@media ${screen("ml")} {
		grid-column: ${({ partIndex }) =>
			partIndex === 0 ? "6 / span 20" : "22 / span 20"};
	}

	@media ${screen("mx")} {
		grid-column: ${({ partIndex }) =>
			partIndex === 0 ? "9 / span 12" : "-22 / span 12"};
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@media ${screen("ml")} {
			width: 80%;
		}
	}
`;

export default function RadicalDecline({ parts }) {
	return (
		<Container>
			<DominosTopWrapper>
				<DominosTop />
			</DominosTopWrapper>
			{parts.map(({ content }, i) => {
				return (
					<Part key={i}>
						{i === 1 ? (
							<DominosMiddle>
								<Copy>
									<Content partIndex={i}>
										<div dangerouslySetInnerHTML={{ __html: content }} />
									</Content>
								</Copy>
							</DominosMiddle>
						) : (
							<Copy>
								<Content partIndex={i}>
									<div dangerouslySetInnerHTML={{ __html: content }} />
								</Content>
							</Copy>
						)}
					</Part>
				);
			})}
			<DominosBottomWrapper>
				<DominosBottom />
			</DominosBottomWrapper>
		</Container>
	);
}
