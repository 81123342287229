import { transform } from "framer-motion";
import { mat4 } from "gl-matrix";
// import { loadTexture } from "../utils/loadTexture";
// import face6 from "../dice-face-6.png";
// import face1 from "../dice-face-1.png";
import twoFace from "../dice-map.png";
import {
	createBufferInfoFromArrays,
	createTexture,
	drawBufferInfo,
	// primitives,
	setBuffersAndAttributes,
	setUniforms,
} from "twgl.js";

export class Dice {
	rotateXOutput;
	rotateInput;
	rotateYOutput;
	rotateZOutput;
	heightInput;
	heightOutput;
	heightEasing;
	xInput;
	xOutput;
	yInput;
	yOutput;
	size = 500;
	constructor(
		ctx,
		rotateInput,
		rotateXOutput,
		rotateYOutput,
		rotateZOutput,
		heightInput,
		heightOutput,
		heightEasing,
		xInput,
		xOutput,
		yInput,
		yOutput,
		size = 500
	) {
		this.rotateInput = rotateInput;
		this.rotateXOutput = rotateXOutput;
		this.rotateYOutput = rotateYOutput;
		this.rotateZOutput = rotateZOutput;
		this.heightInput = heightInput;
		this.heightOutput = heightOutput;
		this.heightEasing = heightEasing;
		this.xInput = xInput;
		this.xOutput = xOutput;
		this.yInput = yInput;
		this.yOutput = yOutput;
		this.size = size;
		this.ctx = ctx;
		this.init();
	}
	drawScene(programInfo, progress, uniforms) {
		let gl = this.ctx;
		const rotateX = transform(progress, this.rotateInput, this.rotateXOutput);
		const rotateY = transform(progress, this.rotateInput, this.rotateYOutput);
		const rotateZ = transform(progress, this.rotateInput, this.rotateZOutput);
		const x = transform(progress, this.xInput, this.xOutput);
		const y = transform(progress, this.yInput, this.yOutput);
		const z = transform(progress, this.heightInput, this.heightOutput, {
			ease: this.heightEasing,
		});
		const scale = [this.size, this.size, this.size];
		let rotationMatrix = mat4.create();
		mat4.rotateX(rotationMatrix, rotationMatrix, rotateX);
		mat4.rotateY(rotationMatrix, rotationMatrix, rotateY);
		mat4.rotateZ(rotationMatrix, rotationMatrix, rotateZ);
		let scaleMatrix = mat4.create();
		scaleMatrix = mat4.scale(scaleMatrix, scaleMatrix, scale);
		let translateMatrix = mat4.fromTranslation([], [x, z, y]); //3, 1, 0

		let modelViewMatrix = mat4.multiply([], translateMatrix, rotationMatrix);
		modelViewMatrix = mat4.multiply(
			modelViewMatrix,
			modelViewMatrix,
			scaleMatrix
		);
		// const normalMatrix = mat4.create();
		// mat4.invert(normalMatrix, modelViewMatrix);
		// mat4.transpose(normalMatrix, normalMatrix);
		let _uniforms = {
			u_world: modelViewMatrix,
			u_texture: this.cubeTexture,
			u_colorMult: [1, 1, 1, 1], // lightgreen
			u_color: [0, 0, 1, 1],
		};

		gl.useProgram(programInfo.program);
		// console.log(gl.getActiveAttrib(programInfo.program, 2));
		setBuffersAndAttributes(gl, programInfo, this.buffers);
		setUniforms(programInfo, uniforms);
		setUniforms(programInfo, _uniforms);
		drawBufferInfo(gl, this.buffers);
	}

	init() {
		const data = {
			position: [
				// Front face
				-1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0, 1.0, 1.0, -1.0, 1.0, 1.0,

				// Back face
				-1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, -1.0, -1.0,

				// Top face
				-1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, -1.0,

				// Bottom face
				-1.0, -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, -1.0, -1.0, 1.0,

				// Right face
				1.0, -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0, 1.0, 1.0, -1.0, 1.0,

				// Left face
				-1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, -1.0,
			],
			normal: [
				0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0,

				// Back
				0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0,

				// Top
				0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0,

				// Bottom
				0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0,

				// Right
				1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0,

				// Left
				-1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0, -1.0, 0.0, 0.0,
			],
			texcoord: [
				// Front
				0.0, 0.0, 0.5, 0.0, 0.5, 1.0, 0.0, 1.0,
				// Back
				0.0, 0.0, 0.5, 0.0, 0.5, 1.0, 0.0, 1.0,
				// Top
				0.0, 0.0, 0.5, 0.0, 0.5, 1.0, 0.0, 1.0,
				// Bottom
				0.5, 0.0, 1.0, 0.0, 1.0, 1.0, 0.5, 1.0,
				// Right
				0.0, 0.0, 0.5, 0.0, 0.5, 1.0, 0.0, 1.0,
				// Left
				0.0, 0.0, 0.5, 0.0, 0.5, 1.0, 0.0, 1.0,
			],
			indices: [
				// front
				0, 1, 2, 0, 2, 3,
				// back
				4, 5, 6, 4, 6, 7,
				// top
				8, 9, 10, 8, 10, 11,
				// bottom
				12, 13, 14, 12, 14, 15,
				// right
				16, 17, 18, 16, 18, 19,
				// left
				20, 21, 22, 20, 22, 23,
			],
		};
		this.buffers = createBufferInfoFromArrays(this.ctx, data);
		this.cubeTexture = createTexture(this.ctx, {
			src: twoFace,
			color: [0, 0, 0, 0],
		});
	}

	cleanup() {
		this.ctx.deleteTexture(this.cubeTexture);
		this.ctx.deleteBuffer(this.buffers.indices);
	}
}
