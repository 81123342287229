import {
	createProgramFromSources,
	createProgramInfoFromProgram,
	createTexture,
	drawBufferInfo,
	primitives,
	setBuffersAndAttributes,
	setUniforms,
} from "twgl.js";
import { mat4 } from "gl-matrix";
import floorVert from "../shaders/floor.vert";
import floorFrag from "../shaders/floor.frag";

export class Floor {
	ctx;
	constructor(ctx) {
		this.ctx = ctx;
		this.init();
	}
	init() {
		this.floorProgramInfo = createProgramInfoFromProgram(
			this.ctx,
			createProgramFromSources(this.ctx, [floorVert, floorFrag])
		);
		this.planeBuffer = primitives.createPlaneBufferInfo(
			this.ctx,
			1000,
			10000,
			10,
			10
		);
		this.planeTexture = createTexture(this.ctx, {
			mag: this.ctx.NEAREST,
			min: this.ctx.LINEAR,
			src: [
				255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
				255, 255,
			],
		});
	}
	drawScene(programInfo, progress, uniforms, shadow) {
		let gl = this.ctx;
		let planeViewMatrix = mat4.identity([]);
		mat4.translate(planeViewMatrix, planeViewMatrix, [0, 0, 0]);
		// mat4.rotateX(planeViewMatrix, planeViewMatrix, degToRad(90));
		// mat4.rotateY(planeViewMatrix, planeViewMatrix, degToRad(90));
		// mat4.scale(planeViewMatrix, planeViewMatrix, [1500, 1500, 1500]);
		// const normalMatrix = mat4.create();
		// mat4.invert(normalMatrix, planeViewMatrix);
		// mat4.transpose(normalMatrix, normalMatrix);
		let pInfo = shadow ? programInfo : this.floorProgramInfo;
		gl.useProgram(pInfo.program);
		let _uniforms = {
			u_world: planeViewMatrix,
			u_texture: this.planeTexture,
			u_colorMult: [0.752941176, 0.752941176, 0.752941176, 1], // lightblue
			u_color: [1, 0, 0, 1],
		};

		// console.log(gl.getActiveAttrib(this.programInfo.program, 2));
		setBuffersAndAttributes(gl, pInfo, this.planeBuffer);
		setUniforms(pInfo, uniforms);
		setUniforms(pInfo, _uniforms);
		drawBufferInfo(gl, this.planeBuffer);
	}

	cleanup() {
		this.ctx.deleteTexture(this.planeTexture);
		this.ctx.deleteBuffer(this.planeBuffer.indices);
		this.ctx
			.getAttachedShaders(this.floorProgramInfo.program)
			?.forEach((shader) => {
				this.ctx.detachShader(this.floorProgramInfo.program, shader);
				this.ctx.deleteShader(shader);
			});
		this.ctx.deleteProgram(this.floorProgramInfo.program);
	}
}
