const priveleges = {
	white: {
		name: "Born White",
		stars: 2,
		title: "You’ve been assigned a white character.",
		description:
			"Your starting wage is 20% higher, and its growth increased by 20%.",
		initialEffect: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			newState.income = newState.income * 1.2;
			newState.incomeGrowthMultiplier = newState.incomeGrowthMultiplier * 1.2;
			return { state: newState, statuses: newStatuses };
		},
	},
	male: {
		name: "Born Male",
		stars: 2,
		title: "You’ve been assigned a male character.",
		description:
			"Your starting wage is 15% higher, and its growth increased by 15%.",
		initialEffect: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			newState.income = newState.income * 1.15;
			newState.incomeGrowthMultiplier = newState.incomeGrowthMultiplier * 1.25;
			return { state: newState, statuses: newStatuses };
		},
	},
	upperMiddleClass: {
		name: "Silver Spoon",
		stars: 3,
		title: "Your parents are upper middle class.",
		description:
			"Context or Chance Cards with negative impact can’t affect you before you’re 40.",
	},
	privateInsurance: {
		name: "Privately Insured",
		stars: 3,
		title: "Hats off! Your character can afford private insurance.",
		description:
			"The probability of picking Health-related Cards is reduced by 50% and their impact by 80%.",
	},
	educatedFamily: {
		name: "Educated family",
		stars: 3,
		title: "Your character’s parents both achieved higher education.",
		description:
			"The cost of an Education Chance Card is annulled. Income starts at 22 but increases by 25% every 10 years.",
	},
	networkEffect: {
		name: "Well Connected",
		stars: 2,
		title: "Your character’s parents have a significant professional network.",
		description: "The Unemployment Chance Cards can’t affect you negatively.",
	},
	noPrivilege: {
		name: "No Privilege",
		stars: 1,
		title: "You're on your own.",
		description: "Your character starts with no privilege whatsoever.",
	},
};

export default priveleges;
