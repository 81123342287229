import React from "react";
import { motion, useTransform, useSpring } from "framer-motion";
import styled from "@emotion/styled";

const ParallaxEl = styled(motion.div)`
	will-change: transform;
`;

export default function Parallax({
	progress,
	containerHeight,
	parallaxAmount,
	children,
	attribute = "y",
}) {
	const transform = useSpring(
		useTransform(
			progress,
			[0, 1],
			[
				containerHeight * parallaxAmount * 0.5,
				containerHeight * parallaxAmount * -0.5,
			]
		),
		{
			stiffness: 400,
			damping: 90,
		}
	);

	return <ParallaxEl style={{ [attribute]: transform }}>{children}</ParallaxEl>;
}
