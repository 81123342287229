import privileges from "../data/privileges";
import contextCards from "../data/context";

export function getStartingCardsFromGame(game) {
	let privilege1;
	let privilege1Enabled = true;
	let privilege2;
	let privilege2Enabled = true;
	let context;
	if (game) {
		let privilegeKeys = Object.keys(game.privileges);
		privilege1 = privileges[privilegeKeys[0]];
		if (!privilege1) {
			let key = privilegeKeys[0].replace("_disabled", "");
			privilege1 = privileges[key];
			privilege1Enabled = false;
		}
		privilege2 = privileges[privilegeKeys[1]];
		if (!privilege2) {
			let key = privilegeKeys[1].replace("_disabled", "");
			privilege2 = privileges[key];
			privilege2Enabled = false;
		}
		context = contextCards[Object.keys(game.contexts)[0]];
	}
	return {
		privilege1,
		privilege1Enabled,
		privilege2,
		privilege2Enabled,
		context,
	};
}
