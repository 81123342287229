export function wealthClass(yearsOfCol) {
	let level = wealthLevel(yearsOfCol);
	switch (level) {
		case 1:
			return "Poverty";
		case 2:
			return "Indebted";
		case 3:
			return "Breakeven";
		case 4:
			return "Income Surplus";
		case 5:
			return "Assets Generate Income";
		case 6:
			return "Assets Generate Wealth";
		default:
			return "Poverty";
	}
}

export function wealthLevel(yearsOfCol) {
	if (yearsOfCol > 30) {
		return 6;
	}
	if (yearsOfCol > 20) {
		return 5;
	}
	if (yearsOfCol > 10) {
		return 4;
	}
	if (yearsOfCol > 0) {
		return 3;
	}
	if (yearsOfCol > -10) {
		return 2;
	}
	if (yearsOfCol > -20) {
		return 1;
	}
	return 1;
}
