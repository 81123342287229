// import man from "../../../../../images/big-dice-character.png";
import man from "../man-sprite.png";
import {
	createBufferInfoFromArrays,
	createProgramFromSources,
	createProgramInfoFromProgram,
	createTexture,
	drawBufferInfo,
	primitives,
	setBuffersAndAttributes,
	setUniforms,
} from "twgl.js";
import { mat4 } from "gl-matrix";
import { degToRad } from "../utils/degToRad";
import manVert from "../shaders/man.vert";
import manFrag from "../shaders/man.frag";
import shadowVert from "../shaders/man-shadow.vert";
import shadowFrag from "../shaders/man-shadow.frag";
export class WheelchairMan {
	ctx;
	constructor(ctx) {
		this.ctx = ctx;
		this.init();
	}

	init() {
		this.manProgramInfo = createProgramInfoFromProgram(
			this.ctx,
			createProgramFromSources(this.ctx, [manVert, manFrag])
		);
		this.shadowProgramInfo = createProgramInfoFromProgram(
			this.ctx,
			createProgramFromSources(this.ctx, [shadowVert, shadowFrag])
		);
		// this.buffer = primitives.createPlaneBufferInfo(this.ctx, 3.72, 5.97, 1, 1);
		this.shadowBuffer = primitives.createPlaneBufferInfo(
			this.ctx,
			3.72,
			5.97,
			1,
			1
		);
		let manArrays = primitives.createPlaneVertices(5.34, 8.58, 1, 1);
		manArrays.texcoord = new Float32Array([
			0.260986328, 0, 0.521484376, 0, 0.260986328, 0.837890625, 0.521484376,
			0.837890625,
		]);
		let shadowArrays = primitives.createPlaneVertices(5.34, 8.58, 1, 1);
		shadowArrays.texcoord = new Float32Array([
			0, 0, 0.260742188, 0, 0, 0.837890625, 0.260742188, 0.837890625,
		]);
		this.buffer = createBufferInfoFromArrays(this.ctx, manArrays);
		this.shadowBuffer = createBufferInfoFromArrays(this.ctx, shadowArrays);
		this.texture = createTexture(this.ctx, {
			src: man,
			auto: true,
			premultiplyAlpha: this.ctx.UNPACK_PREMULTIPLY_ALPHA_WEBGL,
			color: [0, 0, 0, 0],
		});
	}

	drawScene(programInfo, progress, uniforms, shadow) {
		let gl = this.ctx;
		let planeViewMatrix = mat4.identity([]);
		mat4.scale(planeViewMatrix, planeViewMatrix, [0.85, 0.85, 0.85]);
		mat4.translate(planeViewMatrix, planeViewMatrix, [7.5, 4, 28]);
		mat4.rotateX(planeViewMatrix, planeViewMatrix, degToRad(65));
		mat4.rotateZ(planeViewMatrix, planeViewMatrix, degToRad(0));
		mat4.rotateY(planeViewMatrix, planeViewMatrix, degToRad(-5));
		// mat4.rotateY(planeViewMatrix, planeViewMatrix, degToRad(90));
		// mat4.scale(planeViewMatrix, planeViewMatrix, [1500, 1500, 1500]);
		// const normalMatrix = mat4.create();
		// mat4.invert(normalMatrix, planeViewMatrix);
		// mat4.transpose(normalMatrix, normalMatrix);
		let shadowMatrix = mat4.identity([]);
		mat4.translate(shadowMatrix, shadowMatrix, [10, 0.1, 24.5]);
		mat4.scale(shadowMatrix, shadowMatrix, [1.3, 0.8, 0.6]);
		mat4.rotateY(shadowMatrix, shadowMatrix, degToRad(60));

		let _uniforms = {
			u_world: shadowMatrix,
			u_texture: this.texture,
			u_colorMult: [0.752941176, 0.752941176, 0.752941176, 1], // lightblue
			u_color: [1, 0, 0, 1],
		};
		let pInfo = shadow ? programInfo : this.shadowProgramInfo;
		gl.useProgram(pInfo.program);

		setBuffersAndAttributes(gl, pInfo, this.shadowBuffer);
		setUniforms(pInfo, uniforms);
		setUniforms(pInfo, _uniforms);
		drawBufferInfo(gl, this.shadowBuffer);
		pInfo = shadow ? programInfo : this.manProgramInfo;
		gl.useProgram(pInfo.program);

		_uniforms = {
			u_world: planeViewMatrix,
			u_texture: this.texture,
			u_colorMult: [0.752941176, 0.752941176, 0.752941176, 1], // lightblue
			u_color: [1, 0, 0, 1],
		};
		// console.log(gl.getActiveAttrib(this.programInfo.program, 2));
		setBuffersAndAttributes(gl, pInfo, this.buffer);
		setUniforms(pInfo, uniforms);
		setUniforms(pInfo, _uniforms);
		drawBufferInfo(gl, this.buffer);
	}
	cleanup() {
		this.ctx.deleteTexture(this.texture);
		this.ctx.deleteBuffer(this.shadowBuffer.indices);
		this.ctx.deleteBuffer(this.buffer.indices);
		this.ctx
			.getAttachedShaders(this.shadowProgramInfo.program)
			?.forEach((shader) => {
				this.ctx.detachShader(this.shadowProgramInfo.program, shader);
				this.ctx.deleteShader(shader);
			});
		this.ctx.deleteProgram(this.shadowProgramInfo.program);

		this.ctx
			.getAttachedShaders(this.manProgramInfo.program)
			?.forEach((shader) => {
				this.ctx.detachShader(this.manProgramInfo.program, shader);
				this.ctx.deleteShader(shader);
			});
		this.ctx.deleteProgram(this.manProgramInfo.program);
	}
}
