import React, { memo } from "react";
import styled from "@emotion/styled";
import { fontSize, spacing, screen } from "../../../../styles/mixins";
const StartingStats = styled.div`
	grid-column: 4 / -4;
	grid-template-columns: 1fr auto;
	${spacing("marginTop", "m")};
	@media ${screen("md")} {
		margin-top: 0;
		grid-column: 27 / -4;
	}
	@media ${screen("lg")} {
		grid-column: 27 / 41;
	}
`;
const StatsRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
const StatsLabel = styled.div`
	font-weight: 600;
	${fontSize(0)};
	${spacing("marginBottom", "xs")};
`;
const SmallStatsLabel = styled.div`
	${fontSize(-1)};
	${spacing("marginBottom", "s")};
`;
const StatsValue = styled.div`
	font-weight: 600;
	text-align: right;
	${fontSize(0)};
`;
const SmallStatsValue = styled.div`
	text-align: right;
	${fontSize(-1)};
	font-weight: 600;
`;
const SmallTitle = styled.h5`
	${fontSize(0)};
	font-weight: 600;
	${spacing("marginBottom", "m")};
`;
export default memo(function BaseStartingConditions({
	initialState,
	formatWealth,
}) {
	return (
		<StartingStats>
			<SmallTitle>STARTING ASSUMPTIONS</SmallTitle>
			<StatsRow>
				<StatsLabel>Average wage per annum</StatsLabel>
				<StatsValue>${formatWealth(initialState.income)}</StatsValue>
			</StatsRow>
			<StatsRow>
				<SmallStatsLabel>AVERAGE WAGE GROWTH PER ANNUM</SmallStatsLabel>
				<SmallStatsValue>
					{initialState.incomeGrowthMultiplier * 100}%
				</SmallStatsValue>
			</StatsRow>
			<StatsRow>
				<StatsLabel>Average living costs per annum</StatsLabel>
				<StatsValue>${formatWealth(initialState.costOfLiving)}</StatsValue>
			</StatsRow>
			<StatsRow>
				<SmallStatsLabel>AVERAGE LIVING COSTS GROWTH PER ANNUM</SmallStatsLabel>
				<SmallStatsValue>
					{Math.round((initialState.inflation - 1) * 100 * 100) / 100}%
				</SmallStatsValue>
			</StatsRow>
		</StartingStats>
	);
});
