import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import GameIntro from "./GameIntro";
import { format } from "d3-format";
import { AnimatedStartingConditionCard } from "./StartingConditionCard";
import { css } from "@emotion/react";
import { spacing } from "../../../../styles/mixins";
import { useInView } from "react-intersection-observer";
import { getStartingCardsFromGame } from "./util/getStartingCardsFromGame";
import MobileEvents from "./MobileEvents";
import { generateGame, generateGames } from "./util/game";
import { useGetResizer } from "../../../../contexts/Resizer";
const Container = styled.div``;
const StartingCards = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	${spacing("marginBottom", "m")};
	${spacing("marginTop", "m")};
	perspective: 1300px;
`;
const formatWealth = format(",.0f");
export default function GameMobile({
	game,
	otherGames,
	title,
	subtitle,
	introduction,
	about,
	keyId,
	initialState,
	setResetGame,
	setGame,
	setOtherGames,
	resetGame,
}) {
	const topResetRef = useRef();
	const area = useGetResizer();
	const containerRef = useRef();
	const [containerBounds, setContainerBounds] = useState({
		top: 0,
		bottom: 100,
		width: 1440,
		height: 830,
	});
	const { inView: card1InView, ref: card1Ref } = useInView({ threshold: 0.5 });
	const { inView: card2InView, ref: card2Ref } = useInView({ threshold: 0.5 });
	const { inView: card3InView, ref: card3Ref } = useInView({ threshold: 0.5 });
	useEffect(() => {
		let element = containerRef.current;
		let scrollY = window.scrollY;
		let rect = element.getBoundingClientRect();
		setContainerBounds({
			top: rect.top + scrollY,
			bottom: rect.bottom + scrollY - window.innerHeight,
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}, [area]);
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (resetGame) {
						setResetGame(false);
						let game = generateGame(initialState);
						setGame(game);
						let otherGames = generateGames(initialState, 50);
						setOtherGames(otherGames);
					}
				}
			});
		});
		observer.observe(topResetRef.current);
		return () => {
			observer.disconnect();
		};
	}, [resetGame, setGame, setResetGame, setOtherGames, initialState]);
	const {
		privilege1,
		privilege1Enabled,
		privilege2,
		privilege2Enabled,
		context,
	} = getStartingCardsFromGame(game);

	const startOver = useCallback(() => {
		window.scrollTo(0, containerBounds.top);
		setResetGame(false);
		setGame(generateGame(initialState));
		setOtherGames(generateGames(initialState, 50));
	}, [containerBounds, setResetGame, setGame, setOtherGames, initialState]);

	return (
		<Container ref={containerRef}>
			<GameIntro
				{...{
					title,
					subtitle,
					introduction,
					about,
					keyId,
					initialState,
					formatWealth,
					topResetRef,
				}}
			/>
			<StartingCards>
				<AnimatedStartingConditionCard
					ref={card1Ref}
					card={privilege1}
					enabled={privilege1Enabled}
					flipped={card1InView}
					css={css`
						${spacing("marginBottom", "s")};
					`}
				/>
				<AnimatedStartingConditionCard
					ref={card2Ref}
					card={privilege2}
					enabled={privilege2Enabled}
					flipped={card2InView}
					css={css`
						${spacing("marginBottom", "s")};
					`}
				/>
				<AnimatedStartingConditionCard
					ref={card3Ref}
					card={context}
					enabled={true}
					type={"context"}
					flipped={card3InView}
				/>
			</StartingCards>
			<MobileEvents
				game={game}
				formatWealth={formatWealth}
				startOver={startOver}
			/>
		</Container>
	);
}
