import React from "react";
import { motion, useTransform } from "framer-motion";
import styled from "@emotion/styled";
// import { easing } from "../../../../util/math";
import { css } from "@emotion/react";
import { transparentize } from "polished";
import picketImage from "../../../../images/wide-sign.png";
import picketImageTall from "../../../../images/tall-sign.png";
import { fontBrixtonWood } from "../../../../styles/typography";

const SIZES = {
	wide: {
		width: 300,
		height: 200,
		poleHeight: 200,
	},
	tall: {
		width: 200,
		height: 300,
		poleHeight: 150,
	},
	// year: { width: 350, height: 250, poleHeight: 250 },
};
const PicketContainer = styled(motion.div)`
	${({ size }) => {
		const sizeValues = SIZES[size];
		return css`
			width: ${sizeValues.width}px;
			height: ${sizeValues.height + sizeValues.poleHeight}px;
			padding-bottom: ${sizeValues.poleHeight}px;
			padding-top: ${size === "wide" ? 19 : 10}px;
			background-image: url(${size === "wide" ? picketImage : picketImageTall});
		`;
	}}
	/* border: 1px solid #777777; */
	position: absolute;
	bottom: 0;
	left: ${({ left }) => left};
	/* background-color: ${({ backgroundColor }) => backgroundColor}; */
	background-size: 100% 100%;
	border-radius: 4px;
	text-align: center;
	transform-origin: bottom center;
	padding-left: 15px;
	padding-right: 15px;
	color: #111111;
	display: flex;
	justify-content: center;
	align-items: center;
	${fontBrixtonWood};
	font-size: 1.9em;
	line-height: 1;

	/* &:after {
		content: " ";
		bottom: 0;
		${({ size }) => {
		const sizeValues = SIZES[size];
		return css`
			height: ${sizeValues.poleHeight}px;
		`;
	}}
		width: 14px;
		/* border: 1px solid #777777; */
	/* position: absolute;
		left: 50%;
		transform: translateY(100%) translateX(-50%);
		background-color: ${({ backgroundColor }) => backgroundColor};
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background: ${({ backgroundColor }) => `linear-gradient(
			0deg,
			${transparentize(1, backgroundColor)} 0%,
			${transparentize(0, backgroundColor)} 50%
		)`};
	} */
`;
// const bobbing = [0, 2.2, 4.6, 6.2, 8, 9];
export default function Picket({
	progress,
	zProgress,
	zValues,
	opacityProgress,
	opacityValues,
	left,
	zIndex = 1,
	rotate,
	children,
	size,
	// yearPicket,
}) {
	const z = useTransform(progress, zProgress, zValues);

	const opacity = useTransform(progress, opacityProgress, opacityValues);

	const visibility = useTransform(progress, (progress) => {
		if (progress > zProgress[0] && progress < zProgress[1]) {
			return "visible";
		} else {
			return "hidden";
		}
	});

	const sizeValues = SIZES[size];
	let color = "#444444";
	if (rotate < 0) {
		let proportion = rotate / -90;
		color = `hsl(0, 0%, ${Math.round(40 + proportion * 60)}%)`;
	} else if (rotate === 0) {
		color = "hsl(0, 0%, 40%)";
	}

	return (
		<PicketContainer
			size={size}
			backgroundColor={color}
			// yearPicket={yearPicket}
			left={`calc(${left} - ${sizeValues.width / 2}px)`}
			style={{
				y: z,
				opacity,
				zIndex,
				rotateX: -90,
				visibility,
				// z: sizeValues.poleHeight,
			}}>
			{children}
		</PicketContainer>
	);
}
