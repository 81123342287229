import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import { spacing } from "../../../styles/mixins";

const Visualisation = styled.div`
	${spacing("marginTop", "m")};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export default function SnakesAndLadders() {
	return (
		<Visualisation>
			<StaticImage
				src={"../../../images/snakes-and-ladders.png"}
				alt={"Snakes and ladders"}
			/>
		</Visualisation>
	);
}
