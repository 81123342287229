import React, { memo } from "react";
import styled from "@emotion/styled";
import { AnimatedChanceCard } from "./ChanceCard";
import House from "./House";
import { spacing } from "../../../../styles/mixins";
import { useInView } from "react-intersection-observer";
import { h3, h4 } from "../../../../styles/typography";
import Retire from "./Retire";
const EventContainer = styled.div`
	${spacing("marginTop", "l")};
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	${spacing("gridRowGap", "l")};
	width: 100%;
	overflow: hidden;
`;
const CardContainer = styled.div`
	perspective: 1300px;
`;
const MainScreenWealth = styled.div`
	${h4};
	color: ${({ positive = true }) => (positive ? "green" : "red")};
`;
const CurrentAge = styled.div`
	${h3};
	opacity: 0.5;
	margin-right: 15px;
	${spacing("marginBottom", "xxs")};
`;
const WealthContainer = styled.div`
	text-align: center;
`;
const Wealth = ({ currentWealth, formatWealth, age }) => {
	return (
		<WealthContainer>
			<CurrentAge>At {age} you have </CurrentAge>
			<MainScreenWealth positive={currentWealth >= 0}>
				{currentWealth < 0 ? "-" : ""}${formatWealth(Math.abs(currentWealth))}
			</MainScreenWealth>
		</WealthContainer>
	);
};
export default memo(function MobileEvents({ game, formatWealth, startOver }) {
	const { inView: card1InView, ref: card1Ref } = useInView({ threshold: 0.5 });
	const { inView: card2InView, ref: card2Ref } = useInView({ threshold: 0.5 });
	const { inView: card3InView, ref: card3Ref } = useInView({ threshold: 0.5 });
	const { inView: card4InView, ref: card4Ref } = useInView({ threshold: 0.5 });
	return (
		<EventContainer>
			<Wealth
				age={23}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[23 - 18].wealth : 0}
			/>
			{game && <House state={game.game[25 - 18]} />}
			<Wealth
				age={27}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[27 - 18].wealth : 0}
			/>
			<CardContainer>
				<AnimatedChanceCard
					ref={card1Ref}
					flipped={card1InView}
					chance={game ? game.chanceCards[0] : false}
				/>
			</CardContainer>
			<Wealth
				age={33}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[33 - 18].wealth : 0}
			/>
			{game && <House state={game.game[35 - 18]} />}
			<Wealth
				age={37}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[37 - 18].wealth : 0}
			/>
			<CardContainer>
				<AnimatedChanceCard
					chance={game ? game.chanceCards[1] : false}
					ref={card2Ref}
					flipped={card2InView}
				/>
			</CardContainer>
			<Wealth
				age={43}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[43 - 18].wealth : 0}
			/>
			{game && <House state={game.game[45 - 18]} />}
			<Wealth
				age={47}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[47 - 18].wealth : 0}
			/>
			<CardContainer>
				<AnimatedChanceCard
					chance={game ? game.chanceCards[2] : false}
					ref={card3Ref}
					flipped={card3InView}
				/>
			</CardContainer>
			<Wealth
				age={53}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[53 - 18].wealth : 0}
			/>
			{game && <House state={game.game[55 - 18]} />}
			<Wealth
				age={57}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[57 - 18].wealth : 0}
			/>
			<CardContainer>
				<AnimatedChanceCard
					chance={game ? game.chanceCards[3] : false}
					ref={card4Ref}
					flipped={card4InView}
				/>
			</CardContainer>
			<Wealth
				age={63}
				formatWealth={formatWealth}
				currentWealth={game ? game.game[63 - 18].wealth : 0}
			/>
			<Retire game={game} state={game.game[65 - 18]} startOver={startOver} />
		</EventContainer>
	);
});
