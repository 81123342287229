import * as React from "react";

import Seo from "../components/Seo";
import importDirectory from "../util/importDirectory";
import { graphql } from "gatsby";
import Cover from "../components/Feature/ActOne/Cover/Cover";
import { motion } from "framer-motion";
import useResizeObserver from "use-resize-observer";
import { useResizer } from "../contexts/Resizer";
import { useEffect } from "react";
import shareImage from "../images/share-image-act-1.jpg";
import { useUIState } from "../contexts/UIStore";

let features = importDirectory(
	require.context("../components/", true, /Feature\/ActOne\/[a-zA-Z_-]*\.js$/),
	"default"
);
let structure = importDirectory(
	require.context("../components/", true, /Structure\/[a-zA-Z_-]*\.js$/),
	"default"
);
let sectionLayouts = importDirectory(
	require.context("../components/", true, /SectionLayout\/[a-zA-Z_-]*\.js$/),
	"default"
);
let components = { ...structure, ...features };
let animations = {
	enter: {
		opacity: 1,
		transition: {
			type: "tween",
			duration: 0.4,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			type: "tween",
			duration: 0.4,
		},
	},
};
const APromiseBroken = ({
	data: {
		allAct1Yaml: { nodes },
	},
}) => {
	const { ref, width, height } = useResizeObserver();
	const setArea = useResizer();
	useEffect(() => {
		let timeout = setTimeout(() => {
			setArea((area) => {
				if (area.width !== width || area.height !== height) {
					return { width, height };
				} else {
					return area;
				}
			});
		}, 150);

		return () => {
			clearTimeout(timeout);
		};
	}, [width, height, setArea]);

	let uiState = useUIState();
	useEffect(() => {
		uiState.setLockNav(false);
		uiState.setGrain(true);
	}, [uiState]);

	return (
		<motion.div
			ref={ref}
			initial="exit"
			animate="enter"
			exit="exit"
			variants={animations}>
			<Seo
				title="A Promise Broken"
				description={
					"How the average person plays The Game of Life with loaded dice"
				}
				image={shareImage}
			/>
			<Cover
				titleLine1="A <span>PROMISE</span>"
				titleLine2="BROKEN"
				subtitle="Part 01"
			/>
			{nodes.map(({ type, keyId, ...props }) => {
				let Layout = sectionLayouts[type];
				return (
					<Layout
						{...props}
						keyId={keyId}
						components={components}
						key={keyId}
					/>
				);
			})}
		</motion.div>
	);
};

export const pageQuery = graphql`
	query ActOne {
		allAct1Yaml(sort: { fields: order, order: ASC }) {
			nodes {
				body {
					content
					content1
					content2
					quote
					attribution
					link
					linkText
					parts {
						content
					}
					classes {
						title
						content
					}
					subtitle
					title
					type
					introduction
					about
					keyId
					menuTitle
					data {
						childrenCantrilJson {
							id
							color
							title
							data {
								x
								y
							}
						}
						childrenCorporateVsPeopleCsv {
							corporate
							employee
							gdp
							year
						}
						childrenGlobalProtestsCsv {
							Country
							Start
							End
							Movement_Protest
						}
					}
					moreInformation {
						title
						subtitle
						summary
						key
						content
					}
				}
				subtitle
				title
				titleGutter
				menuTitle
				type
				keyId
			}
		}
	}
`;

export default APromiseBroken;
