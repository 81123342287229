import React from "react";
import Graph from "./CapitalismVsPeople/Graph";

export default function CapitalismVsPeople({
	data: { childrenCorporateVsPeopleCsv: data },
	title,
	keys = ["corporate", "employee", "gdp"],
}) {
	return (
		<Graph data={data} keys={keys} title={title} />
	);
}
