import styled from "@emotion/styled";
import { motion, useTransform } from "framer-motion";
import React, { forwardRef, memo } from "react";
import back from "./images/chance-back.png";
import front from "./images/card-front.png";
import { fontRoslindaleDisplay, fontSurt } from "../../../../styles/typography";
import { useDefinitionModal } from "../../../../contexts/DefinitionModalContext";
import ClickForMoreInfo from "./ClickForMoreInfo";
import { css } from "@emotion/react";

const CardContainer = styled(motion.div)`
	width: 360px;
	height: 520px;
	transform-style: preserve-3d;
	cursor: ${({ hasDefinition }) => (hasDefinition ? "pointer" : null)};
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	border-radius: 33px;
`;

const Front = styled.div`
	width: 360px;
	height: 520px;
	position: absolute;
	background-color: #f0f0f0;
	backface-visibility: hidden;
	padding: 50px 55px 35px 55px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	background-image: url(${front});
	background-size: cover;
	border-radius: 33px;
`;
const Back = styled.div`
	width: 360px;
	height: 520px;
	position: absolute;

	background-image: url(${back});
	background-size: cover;
	transform: rotateY(180deg);
	backface-visibility: hidden;
	border-radius: 33px;
`;

const Image = styled.img`
	margin-bottom: 18px;
	width: 250px;
`;
const Title = styled.h4`
	font-size: 18px;
	${fontRoslindaleDisplay};
	font-weight: 586;
	margin-bottom: 14px;
`;
const Description = styled.div`
	font-size: 13px;
	margin-bottom: 10px;
	${fontSurt};
	strong {
		font-weight: 600;
	}
`;

const Prefix = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 20px;
	font-size: 10px;
	${fontSurt};
	font-weight: 600;
	letter-spacing: 0.05em;
`;

export const BaseChanceCard = forwardRef(function ({ chance, ...props }, ref) {
	const key = chance ? chance.moreInfo : false;
	const { setContentKey, setActive } = useDefinitionModal();
	return (
		<CardContainer
			ref={ref}
			{...props}
			hasDefinition={!!key}
			onClick={() => {
				if (key) {
					setContentKey(key);
					setActive(true);
				}
			}}>
			<Front>
				<Prefix>LIFE EVENTS CARD</Prefix>
				{chance && chance.image && (
					<Image src={chance.image} alt={chance.title} />
				)}
				{chance && <Title>{chance.prefix.toUpperCase()}</Title>}
				{chance && (
					<Description dangerouslySetInnerHTML={{ __html: chance.title }} />
				)}
				{chance && (
					<Description
						dangerouslySetInnerHTML={{ __html: chance.description }}
					/>
				)}
				{key && (
					<ClickForMoreInfo
						css={css`
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							bottom: 18px;
						`}>
						WHY IS THIS IMPORTANT
					</ClickForMoreInfo>
				)}
			</Front>
			<Back />
		</CardContainer>
	);
});

export const AnimatedChanceCard = memo(
	forwardRef(function ({ flipped = false, ...props }, ref) {
		return (
			<BaseChanceCard
				{...props}
				ref={ref}
				animate={{
					rotateY: flipped ? 360 : 180,
				}}
				transition={{ bounce: 0 }}
			/>
		);
	})
);

export default memo(function ChanceCard({ age, currentAge, chance }) {
	let rotateY = useTransform(age, [currentAge - 2, currentAge], [180, 360]);
	let z = useTransform(
		age,
		[currentAge - 2, currentAge - 1, currentAge],
		[0, 350 / 2, 0]
	);
	return <BaseChanceCard style={{ rotateY, z }} chance={chance} />;
});
