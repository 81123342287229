import fragShader from "./shaders/shader.frag";
import vertShader from "./shaders/shader.vert";
import colorVert from "./shaders/color.vert";
import colorFrag from "./shaders/color.frag";
// import manVert from "./shaders/man.vert";
// import manFrag from "./shaders/man.frag";
// import { mat3, mat4, vec3 } from "gl-matrix";
import {
	createBufferInfoFromArrays,
	createProgramFromSources,
	// createProgramInfo,
	createProgramInfoFromProgram,
	createTexture,
	m4,
	setBuffersAndAttributes,
	setUniforms,
	// primitives,
	drawBufferInfo,
	createFramebufferInfo,
	resizeCanvasToDisplaySize,
} from "twgl.js";
import { Dice } from "./components/Dice";
import { easing } from "../../../../util/math";
// import face from "./dice-face-6.png";
import { Floor } from "./components/Floor";
import { degToRad } from "./utils/degToRad";
import { WheelchairMan } from "./components/WheelchairMan";
import { transform } from "framer-motion";

export class SceneManager {
	ambientLight = [0.1, 0.1, 0.1];
	directionalLight = [-1.0, 0, 1.0];
	lightColor = [1, 1, 1];
	depthTextureSize = 4096;
	settings = {
		cameraX: 0,
		cameraY: 33,
		cameraZ: 65,
		cameraTargetX: 7,
		cameraTargetXMobile: 12,
		cameraTargetY: 8,
		cameraTargetZ: 20,
		// cameraZ: 250,
		posX: -35.08,
		posY: 35.58,
		posZ: -18.61,
		targetX: 0,
		targetY: 0,
		targetZ: 0,
		projWidth: 200,
		projHeight: 200,
		perspective: false,
		fieldOfView: 120,
		bias: -0.008,
		hideFrustrum: true,
	};
	constructor(canvas, progress) {
		this.canvas = canvas;
		this.progress = progress;
		this.ctx = this.canvas.getContext("webgl", { antialias: true });
		let maxSize = this.ctx.getParameter(this.ctx.MAX_TEXTURE_SIZE);
		this.depthTextureSize = maxSize >= 8192 ? 8192 : maxSize;

		resizeCanvasToDisplaySize(this.canvas);
		this.init();
		this.setupShadows();
		// this.render(0);
		this.setupEventListeners();
	}

	setupEventListeners() {
		this.cleanupProgress = this.progress.onChange(this.render);
	}

	setupShadows() {
		this.depthTexture = createTexture(this.ctx, {
			src: null,
			internalFormat: this.ctx.DEPTH_COMPONENT,
			format: this.ctx.DEPTH_COMPONENT,
			width: this.depthTextureSize,
			height: this.depthTextureSize,
			type: this.ctx.UNSIGNED_INT,
			mag: this.ctx.NEAREST,
			min: this.ctx.NEAREST,
			wrap: this.ctx.CLAMP_TO_EDGE,
			target: this.ctx.TEXTURE_2D,
			auto: false,
		});
		this.unusedTexture = createTexture(this.ctx, {
			src: null,
			width: this.depthTextureSize,
			height: this.depthTextureSize,
			type: this.ctx.UNSIGNED_BYTE,
			mag: this.ctx.NEAREST,
			min: this.ctx.NEAREST,
			wrap: this.ctx.CLAMP_TO_EDGE,
			auto: false,
		});
		this.depthBuffer = createFramebufferInfo(this.ctx, [
			{
				attachment: this.depthTexture,
				attachmentPoint: this.ctx.DEPTH_ATTACHMENT,
			},
			{
				attachment: this.unusedTexture,
				attachmentPoint: this.ctx.COLOR_ATTACHMENT0,
			},
		]);
		this.cubeLinesBufferInfo = createBufferInfoFromArrays(this.ctx, {
			position: [
				-1, -1, -1, 1, -1, -1, -1, 1, -1, 1, 1, -1, -1, -1, 1, 1, -1, 1, -1, 1,
				1, 1, 1, 1,
			],
			indices: [
				0, 1, 1, 3, 3, 2, 2, 0,

				4, 5, 5, 7, 7, 6, 6, 4,

				0, 4, 1, 5, 3, 7, 2, 6,
			],
		});
	}

	init() {
		const ext = this.ctx.getExtension("WEBGL_depth_texture");
		if (!ext) {
			return console.warn("need WEBGL_depth_texture"); // eslint-disable-line
		}
		this.programInfo = createProgramInfoFromProgram(
			this.ctx,
			createProgramFromSources(this.ctx, [vertShader, fragShader])
		);
		this.shadowProgramInfo = createProgramInfoFromProgram(
			this.ctx,
			createProgramFromSources(this.ctx, [colorVert, colorFrag])
		);

		// this.createPlane();
		this.floor = new Floor(this.ctx);
		this.dice1 = new Dice(
			this.ctx,
			[0, 0.9],
			[0, degToRad(-8 * 90)],
			[0, degToRad(1.4 * 90)],
			[0, degToRad(3 * 90)],
			[0, 0.3, 0.5, 0.7, 0.8, 0.9],
			[80, 20, 40, 14, 30, 12],
			[
				easing.inQuad,
				easing.outQuad,
				easing.inQuad,
				easing.outQuad,
				easing.inQuad,
			],
			[0, 0.3, 0.5, 0.7, 0.8, 0.9],
			[41, 37, 32, 28, 25, 23],
			[0, 0.3, 0.5, 0.7, 0.8, 0.9],
			[28, 24, 19, 14, 10, 7],
			12
		);

		this.dice2 = new Dice(
			this.ctx,
			[0, 1],
			[0, degToRad(-8 * 90)],
			[0, degToRad(4.6 * 90)],
			[0, degToRad(3 * 90)],
			[0, 0.4, 0.6, 0.8, 0.9, 1],
			[80, 20, 40, 20, 30, 12],
			[
				easing.inQuad,
				easing.outQuad,
				easing.inQuad,
				easing.outQuad,
				easing.inQuad,
			],
			[0, 0.4, 0.6, 0.8, 0.9, 1],
			[4, -1, -6, -9, -12, -14],
			[0, 0.4, 0.6, 0.8, 0.9, 1],
			[16, 16, 14, 12, 9, 7],
			12
		);
		this.man = new WheelchairMan(this.ctx);
	}

	render = (progress) => {
		let settings = this.settings;
		let gl = this.ctx;
		resizeCanvasToDisplaySize(
			gl.canvas,
			Math.min(1.5, window.devicePixelRatio)
		);
		gl.enable(gl.DEPTH_TEST);
		gl.enable(gl.CULL_FACE);

		//light first;
		let lightMovementY = transform(
			progress,
			[0.9, 1.2],
			[settings.targetY, settings.targetY + 10],
			{ ease: easing.outCubic }
		);
		let lightMovementX = transform(
			progress,
			[0.9, 1.2],
			[settings.targetX, settings.targetX - 18],
			{ ease: easing.outCubic }
		);

		const lightWorldMatrix = m4.lookAt(
			[settings.posX, settings.posY, settings.posZ], // position
			[lightMovementX, lightMovementY, settings.targetZ], // target
			[0, 1, 0] // up
		);
		const lightProjectionMatrix = settings.perspective
			? m4.perspective(
					degToRad(settings.fieldOfView),
					settings.projWidth / settings.projHeight,
					0.5, // near
					10
			  ) // far
			: m4.ortho(
					-settings.projWidth / 2, // left
					settings.projWidth / 2, // right
					-settings.projHeight / 2, // bottom
					settings.projHeight / 2, // top
					0.5, // near
					200
			  ); // far

		// draw to the depth texture

		gl.bindFramebuffer(gl.FRAMEBUFFER, this.depthBuffer.framebuffer);
		gl.viewport(0, 0, this.depthTextureSize, this.depthTextureSize);
		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
		this.drawScene(
			progress,
			this.shadowProgramInfo,
			lightProjectionMatrix,
			lightWorldMatrix,
			m4.identity(),
			lightWorldMatrix,
			true
		);

		gl.bindFramebuffer(gl.FRAMEBUFFER, null);
		gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
		gl.clearColor(1.0, 1.0, 1.0, 1.0);
		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

		let textureMatrix = m4.identity();
		textureMatrix = m4.translate(textureMatrix, [0.5, 0.5, 0.5]);
		textureMatrix = m4.scale(textureMatrix, [0.5, 0.5, 0.5]);
		textureMatrix = m4.multiply(textureMatrix, lightProjectionMatrix);
		// use the inverse of this world matrix to make
		// a matrix that will transform other positions
		// to be relative this world space.
		textureMatrix = m4.multiply(textureMatrix, m4.inverse(lightWorldMatrix));
		//140 = 100 * 1.4
		const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
		const projectionMatrix = m4.perspective(degToRad(60), aspect, 1, 2000);

		// Compute the camera's matrix using look at.
		const cameraPosition = [
			settings.cameraX,
			settings.cameraY,
			settings.cameraZ,
		];
		const target = [
			window.innerWidth > 520
				? settings.cameraTargetX
				: settings.cameraTargetXMobile,
			settings.cameraTargetY,
			settings.cameraTargetZ,
		];
		const up = [0, 1, 0];
		const cameraMatrix = m4.lookAt(cameraPosition, target, up);

		this.drawScene(
			progress,
			this.programInfo,
			projectionMatrix,
			cameraMatrix,
			textureMatrix,
			lightWorldMatrix
		);

		if (!settings.hideFrustrum) {
			const viewMatrix = m4.inverse(cameraMatrix);

			gl.useProgram(this.shadowProgramInfo.program);

			// Setup all the needed attributes.
			setBuffersAndAttributes(
				gl,
				this.shadowProgramInfo,
				this.cubeLinesBufferInfo
			);

			// scale the cube in Z so it's really long
			// to represent the texture is being projected to
			// infinity
			const mat = m4.multiply(
				lightWorldMatrix,
				m4.inverse(lightProjectionMatrix)
			);

			// Set the uniforms we just computed
			setUniforms(this.shadowProgramInfo, {
				u_color: [1, 1, 1, 1],
				u_view: viewMatrix,
				u_projection: projectionMatrix,
				u_world: mat,
			});

			// calls gl.drawArrays or gl.drawElements
			drawBufferInfo(gl, this.cubeLinesBufferInfo, gl.LINES);
		}
	};

	drawScene = (
		progress,
		programInfo,
		projectionMatrix,
		cameraMatrix,
		textureMatrix,
		lightWorldMatrix,
		shadow = false
	) => {
		let gl = this.ctx;
		const viewMatrix = m4.inverse(cameraMatrix);
		let baseUniforms = {
			u_reverseLightDirection: lightWorldMatrix.slice(8, 11),
			u_projection: projectionMatrix,
			u_projectedTexture: this.depthTexture,
			u_textureMatrix: textureMatrix,
			u_view: viewMatrix,
			u_bias: this.settings.bias,
		};

		this.dice1.drawScene(programInfo, progress, baseUniforms);
		this.dice2.drawScene(programInfo, progress, baseUniforms);
		this.floor.drawScene(programInfo, progress, baseUniforms, shadow);

		gl.enable(gl.BLEND);
		gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
		gl.depthMask(false);
		this.man.drawScene(programInfo, progress, baseUniforms, shadow);
		gl.disable(gl.BLEND);
		gl.depthMask(true);
	};

	cleanup() {
		this.cleanupProgress();
		this.ctx.deleteFramebuffer(this.depthBuffer.framebuffer);
		this.ctx.deleteTexture(this.depthTexture);
		this.ctx.deleteTexture(this.unusedTexture);
		this.ctx.getAttachedShaders(this.programInfo.program).forEach((shader) => {
			this.ctx.detachShader(this.programInfo.program, shader);
			this.ctx.deleteShader(shader);
		});
		this.ctx.deleteProgram(this.programInfo.program);

		this.ctx
			.getAttachedShaders(this.shadowProgramInfo.program)
			.forEach((shader) => {
				this.ctx.detachShader(this.shadowProgramInfo.program, shader);
				this.ctx.deleteShader(shader);
			});
		this.ctx.deleteProgram(this.shadowProgramInfo.program);
		this.dice1.cleanup();
		this.dice2.cleanup();
		this.floor.cleanup();
		this.man.cleanup();
	}
}
