import React from "react";
import styled from "@emotion/styled";
import { screen, spacing } from "../../../../styles/mixins";
import { StaticImage } from "gatsby-plugin-image";

const Visualisation = styled.div`
	position: relative;
	width: 100%;

	@media ${screen("md")} {
		padding-top: 5vw;
		padding-bottom: 5vw;
	}

	@media ${screen("lg")} {
		padding-bottom: 0;
	}
`;

const Wrapper = styled.div`
	overflow: hidden;
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 4;
`;

const ImageWrapper = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@media ${screen("md")} {
		display: block;
	}
`;

const Dominos1 = styled.div`
	left: 10%;
	width: 120%;
	z-index: 3;
	${spacing("marginBottom", "l")};
`;

const Dominos2 = styled.div`
	position: absolute;
	top: 50%;
	left: -25%;
	width: 50%;
	transform: translateY(-50%);
	z-index: 2;
	display: none;

	@media ${screen("md")} {
		display: block;
	}

	@media ${screen("ml")} {
		left: -20%;
	}

	@media ${screen("ms")} {
		top: auto;
		bottom: -20%;
		transform: none;
	}
`;

const Dominos3 = styled.div`
	position: absolute;
	bottom: 0%;
	left: -27%;
	width: 50%;
	z-index: 1;
	display: none;

	@media ${screen("md")} {
		display: block;
	}

	@media ${screen("lg")} {
		bottom: -10%;
		left: -17%;
	}

	@media ${screen("ms")} {
		display: none;
	}
`;

export default function DominosMiddle({ children }) {
	return (
		<Visualisation>
			<Wrapper>
				<StaticImage
					src="../../../../images/domino-2-1.png"
					alt="Dominos falling"
					as={Dominos1}
					layout="fullWidth"
					aspectRatio={3181 / 935}
					placeholder="none"
					objectFit="contain"
					objectPosition="center top"
				/>
				<ImageWrapper>
					<StaticImage
						src="../../../../images/domino-2-2.png"
						alt="Dominos falling"
						as={Dominos2}
						layout="fullWidth"
						aspectRatio={1485 / 2828}
						placeholder="none"
						objectFit="contain"
						objectPosition="center top"
					/>
					<StaticImage
						src="../../../../images/domino-2-3.png"
						alt="Dominos falling"
						as={Dominos3}
						layout="fullWidth"
						aspectRatio={1199 / 1883}
						placeholder="none"
						objectFit="contain"
						objectPosition="center top"
					/>
				</ImageWrapper>
				<Content>{children}</Content>
			</Wrapper>
		</Visualisation>
	);
}
