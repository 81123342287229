import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { motion, useTransform } from "framer-motion";
import back from "./images/starting-conditions-back.png";
import { fontRoslindaleDisplay, fontSurt } from "../../../../styles/typography";
import front from "./images/card-front.png";

const CardContainer = styled(motion.div)`
	width: 270px;
	height: 390px;

	transform-style: preserve-3d;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	border-radius: 24px;
`;

const Front = styled.div`
	width: 270px;
	height: 390px;
	position: absolute;
	background-color: #f0f0f0;
	backface-visibility: hidden;
	padding: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ cardEnabled }) => (cardEnabled ? 1 : 0.7)};
	background-image: url(${front});
	background-size: cover;
	border-radius: 24px;
	&:after,
	&:before {
		content: "";
		display: ${({ cardEnabled }) => (cardEnabled ? "none" : "block")};
		position: absolute;
		height: 454px;
		border-radius: 4px;
		width: 10px;
		background-color: red;
		opacity: 0.5;
		transform-origin: 50% 50%;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) rotate(35deg);
	}
	&:before {
		transform: translateX(-50%) translateY(-50%) rotate(-35deg);
	}
`;
const Back = styled.div`
	width: 270px;
	height: 390px;
	position: absolute;
	background-image: url(${back});
	background-size: cover;
	transform: rotateY(180deg);
	backface-visibility: hidden;
	border-radius: 24px;
`;

const Title = styled.h4`
	${fontRoslindaleDisplay};
	margin-bottom: 14px;
	font-size: 18px;
	font-weight: 586;
`;
const Description = styled.div`
	font-size: 13px;
	${fontSurt};
	margin-bottom: 10px;
`;
const Prefix = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 13px;
	font-size: 10px;
	letter-spacing: 0.1em;
	${fontSurt};
	font-weight: 600;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StartingConditionCardBase = forwardRef(function (
	{ card, enabled = true, type = "privilege", ...props },
	ref
) {
	return (
		<CardContainer ref={ref} {...props}>
			<Front cardEnabled={enabled}>
				<Prefix>{type.toUpperCase()} CARD</Prefix>
				{card && (
					<Content>
						<Title>{card.name.toUpperCase()}</Title>
						<Description>{card.title}</Description>
						<Description>{card.description}</Description>
					</Content>
				)}
			</Front>
			<Back />
		</CardContainer>
	);
});

export const AnimatedStartingConditionCard = forwardRef(function (
	{ flipped = false, ...props },
	ref
) {
	return (
		<StartingConditionCardBase
			ref={ref}
			{...props}
			animate={{
				rotateY: flipped ? 360 : 180,
			}}
			transition={{ bounce: 0 }}
		/>
	);
});

export default function StartingConditionCard({
	progress,
	xInput,
	xOutput,
	rotateYInput,
	rotateYOutput,
	zIndexInput,
	zIndexOutput,
	card,
	enabled = true,
	zInput,
	type = "privilege",
}) {
	let x = useTransform(progress, xInput, xOutput);
	let zIndex = useTransform(progress, zIndexInput, zIndexOutput);
	let rotateY = useTransform(progress, rotateYInput, rotateYOutput);
	let z = useTransform(progress, zInput, [0, 270 / 2, 0]);

	return (
		<StartingConditionCardBase
			css={css`
				position: absolute;
				top: 0;
				left: 0;
			`}
			style={{ x, zIndex, rotateY, z }}
			{...{ type, enabled, card }}
		/>
	);
}
