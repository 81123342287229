import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { easing } from "../../../util/math";
import { useGetResizer } from "../../../contexts/Resizer";
import {
	useViewportScroll,
	motion,
	useTransform,
	useSpring,
} from "framer-motion";
import {
	fontSurt,
	h1,
	pMega,
	pStandard,
	fontRoslindaleDisplay,
	richText,
} from "../../../styles/typography";
import { grid, ratio, screen, spacing } from "../../../styles/mixins";
import { colours } from "../../../styles/colours";
import { transparentize } from "polished";
import { design } from "../../../styles/settings";
import { StaticImage } from "gatsby-plugin-image";

const Container = styled.div`
	min-height: 100vh;
	width: 100%;
`;

const HeightWrapper = styled.div`
	min-height: 100vh;
	width: 100%;
	position: relative;

	&::after {
		content: "";
		background-color: white;
		background: linear-gradient(
			0deg,
			${`${transparentize(0, colours.bgWhite)} 50%`},
			${`${transparentize(1, colours.bgWhite)} 100%`}
		);
		bottom: 0;
		left: 0;
		width: 100%;
		height: 150vh;
		position: absolute;
	}
`;

const Screen = styled.div`
	position: sticky;
	height: 100vh;
	top: 0;
	${grid}
	width: 100%;
	text-align: center;
	align-items: center;
	justify-items: center;

	h1 {
		${h1};
	}
`;

const Title = styled(motion.div)`
	grid-column: 3 / -3;
	${pMega};
	position: relative;
	z-index: 7;
	will-change: transform, opacity;
	${richText};

	@media ${screen("md")} {
		grid-column: 5 / -5;
	}

	@media ${screen("ml")} {
		grid-column: 9 / -9;
	}

	span {
		${fontSurt};
		font-size: 0.99em;
	}
`;

const Attribution = styled.cite`
	display: inline-block;
	font-style: normal;
	${spacing("marginTop", "xs")};
	${pStandard};

	@media ${screen("ml")} {
		grid-column: 7 / -7;
	}

	@media ${screen("mx")} {
		grid-column: 9 / -9;
	}

	span {
		${fontRoslindaleDisplay};
		font-style: italic;
		text-transform: lowercase;
	}
`;

const FenceBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	display: flex;
	justify-content: center;
	overflow: hidden;
`;

const FencePickets = styled.div`
	display: flex;
	margin-left: ${`${4 / 5}%`};
`;

const FencePicket = styled(motion.div)`
	flex-grow: 1;
	width: 20%;
	object-fit: cover;
	object-position: top center;
	position: relative;
	margin-left: -4%;
	padding-bottom: ${`${(1075 / 570) * 20}%`};
	will-change: transform;
	${ratio(
		"minWidth",
		0,
		{
			mn: { base: 200, ratio: 1 },
			md: { base: 350, ratio: 1 },
			ms: { base: 480, ratio: 1 },
		},
		design
	)};

	&:nth-child(1) {
		margin-top: calc(${(0 - 2) * 9.6}% + 45vh);
	}
	&:nth-child(2) {
		margin-top: calc(${(1 - 2) * 9.6}% + 45vh);
	}
	&:nth-child(3) {
		margin-top: calc(${(2 - 2) * 9.6}% + 45vh);
	}
	&:nth-child(4) {
		margin-top: calc(${(3 - 2) * 9.6}% + 45vh);
	}
	&:nth-child(5) {
		margin-top: calc(${(4 - 2) * 9.6}% + 45vh);
	}
`;

const ManClimbing = styled.div`
	position: absolute;
	top: 4.3%;
	left: 26.7%;
	width: 20.3%;
	height: 100%;

	img {
		width: 100%;
	}
`;

export default function Cover({ content, attribution }) {
	const height = 4;

	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);
	const [refBottom, setRefBottom] = useState(1);
	const [windowHeight, setWindowHeight] = useState(768);
	const [orientation, setOrientation] = useState("landscape");
	const [containerHeight, setContainerHeight] = useState(height * 0.64);

	const { scrollY } = useViewportScroll();

	const titleProgressY = useSpring(
		useTransform(
			scrollY,
			[refTop, refTop + (refBottom - refTop) * 0.3],
			[0, (refBottom - refTop) * -0.3]
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const titleProgressOpacity = useSpring(
		useTransform(
			scrollY,
			[
				refTop + (refBottom - refTop) * -0.15,
				refTop + (refBottom - refTop) * -0.05,
				refTop + (refBottom - refTop) * 0.05,
				refTop + (refBottom - refTop) * 0.15,
			],
			[0, 1, 1, 0],
			{
				easing: easing.inOutCubic,
			}
		),
		{
			stiffness: 1000,
			damping: 50,
		}
	);

	const picketInputRange = (index) => [
		refTop + (refBottom - refTop) * -1 + windowHeight * 0.15 * index,
		refTop + (refBottom - refTop) * -0.1 + windowHeight * 0.15 * index,
		refTop + windowHeight * (orientation === "landscape" ? 0.3 : 0.15) * index,
		refTop +
			(refBottom - refTop) * 1.7 +
			windowHeight * (orientation === "landscape" ? 0.3 : 0.15) * index,
	];
	const picketOutputRange = (index) => [
		0.25 * height * windowHeight,
		0 * height * windowHeight,
		0 * height * windowHeight,
		-1.8 * height * windowHeight * (1 - index / 8),
	];

	const picket1ProgressY = useSpring(
		useTransform(scrollY, picketInputRange(0), picketOutputRange(0), {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const picket2ProgressY = useSpring(
		useTransform(scrollY, picketInputRange(1), picketOutputRange(1), {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const picket3ProgressY = useSpring(
		useTransform(scrollY, picketInputRange(2), picketOutputRange(2), {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const picket4ProgressY = useSpring(
		useTransform(scrollY, picketInputRange(3), picketOutputRange(3), {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const picket5ProgressY = useSpring(
		useTransform(scrollY, picketInputRange(4), picketOutputRange(4), {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	useEffect(() => {
		const refPosition = ref.current?.getBoundingClientRect();
		const top = refPosition.top + window.pageYOffset;
		const bottom = top + refPosition.height - window.innerHeight;
		setRefTop(top);
		setRefBottom(bottom);
	}, [area]);

	useEffect(() => {
		setContainerHeight(
			orientation === "landscape" ? height * 0.64 : height * 0.48
		);
	}, [orientation]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);

			if (window.innerHeight > window.innerWidth) {
				setOrientation("portrait");
			} else {
				setOrientation("landscape");
			}
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Container style={{ height: `${containerHeight * 100}vh` }}>
			<HeightWrapper ref={ref} style={{ height: `${height * 100}vh` }}>
				<Screen>
					<FenceBg>
						<FencePickets>
							<FencePicket style={{ y: picket1ProgressY }}>
								<StaticImage
									src="../../../images/fence-picket.png"
									alt="Picket fence"
									layout="fullWidth"
									sizes="(min-width: 2560px) 30vw, 50vw"
									placeholder="none"
								/>
							</FencePicket>
							<FencePicket style={{ y: picket2ProgressY }}>
								<StaticImage
									src="../../../images/fence-picket.png"
									alt="Picket fence"
									layout="fullWidth"
									sizes="(min-width: 2560px) 30vw, 50vw"
									placeholder="none"
								/>
							</FencePicket>
							<FencePicket style={{ y: picket3ProgressY }}>
								<StaticImage
									src="../../../images/fence-picket.png"
									alt="Picket fence"
									layout="fullWidth"
									sizes="(min-width: 2560px) 30vw, 50vw"
									placeholder="none"
								/>
								<ManClimbing>
									<StaticImage
										src="../../../images/climber-shadow.png"
										alt="Climber"
										layout="fullWidth"
										sizes="(min-width: 2560px) 20vw, 40vw"
										placeholder="none"
									/>
								</ManClimbing>
							</FencePicket>
							<FencePicket style={{ y: picket4ProgressY }}>
								<StaticImage
									src="../../../images/fence-picket.png"
									alt="Picket fence"
									layout="fullWidth"
									sizes="(min-width: 2560px) 30vw, 50vw"
									placeholder="none"
								/>
							</FencePicket>
							<FencePicket style={{ y: picket5ProgressY }}>
								<StaticImage
									src="../../../images/fence-picket.png"
									alt="Picket fence"
									layout="fullWidth"
									sizes="(min-width: 2560px) 30vw, 50vw"
									placeholder="none"
								/>
							</FencePicket>
						</FencePickets>
					</FenceBg>
					<Title style={{ y: titleProgressY, opacity: titleProgressOpacity }}>
						<div dangerouslySetInnerHTML={{ __html: content }} />
						<Attribution dangerouslySetInnerHTML={{ __html: attribution }} />
					</Title>
				</Screen>
			</HeightWrapper>
		</Container>
	);
}
