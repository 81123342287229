export const BASE_INCOME = 52816;
export const BASE_INCOME_GROWTH_RATE = 0.027;
export const INFLATION_RATE = 1.0188;
export const BASE_COST_OF_LIVING = 41793;
export const DEBT_RATE = 1.09;
export const WEALTH_RATE = 1.08;

export const DEFAULT_STATE = {
	wealth: 0,
	income: BASE_INCOME,
	incomeGrowthMultiplier: BASE_INCOME_GROWTH_RATE,
	costOfLiving: BASE_COST_OF_LIVING,
	age: 18,
	inflation: INFLATION_RATE,
	debtRate: DEBT_RATE,
	wealthRate: WEALTH_RATE,
	savingsMin: 0,
	savingsMax: 0.1,
	chanceCardInterval: 10,
	zoom: 1,
	speed: 1,
};
