import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
	Tooltip,
	XYChart,
	lightTheme,
	Grid,
	Axis,
	LineSeries,
} from "@visx/xychart";
import { curveNatural } from "@visx/curve";
import {
	container,
	spacing,
	screen,
	fontSize,
} from "../../../../styles/mixins";
import {
	fontSurt,
	graphTitle,
	fontRoslindaleDisplay,
} from "../../../../styles/typography";
import { useGetResizer } from "../../../../contexts/Resizer";

const Container = styled.div`
	${spacing("marginTop", "xl")};
	${spacing("marginBottom", "xl")};
	${container};
	.visx-axis-tick {
		text {
			font-size: 12px;
			font-weight: 400;
			fill: black;
			${fontSurt};
		}
	}
	.visx-axis-label {
		font-size: 12px;
		font-weight: 600;
		fill: black;
		${fontSurt};
	}
	path {
		transition: opacity 0.3s ease-in-out;
	}
`;
const GraphContainer = styled.div`
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 10 / -10;
	}

	@media ${screen("mx")} {
		grid-column: 16 / -16;
	}
`;
const ColoredSquare = styled.div`
	display: inline-block;
	width: 11px;
	height: 11px;
	margin-right: 8px;
	background: ${({ color }) => color};
	border-radius: 4px;
`;
const TooltipContainer = styled.div`
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	color: #222222;
	.value {
		display: flex;
		align-items: center;
		font-weight: 400;
		color: #000000;
		margin-bottom: 5px;
	}
`;

const TooltipDate = styled.div`
	${spacing("marginBottom", "xs")}
`;
const Legend = styled.div`
	display: flex;
`;
const LegendItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 0 20px 0 1px;
	cursor: default;
	${fontSize(-2)};
	${fontSurt};
	font-weight: bold;

	&:last-child {
		margin-right: 0;
	}

	@media ${screen("md")} {
		flex-direction: row;
	}

	&:before {
		content: " ";
		display: block;
		border-radius: 50%;
		margin-bottom: 5px;
		width: 9px;
		height: 9px;
		background-color: ${({ color }) => color};

		@media ${screen("md")} {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}
`;

const H3 = styled.h3`
	${graphTitle}
	grid-column: 3 / -3;
	${spacing("marginBottom", "s")};

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 10 / span 17;
	}

	@media ${screen("mx")} {
		grid-column: 16 / span 15;
	}

	span {
		${fontRoslindaleDisplay};
	}
`;

const Attribution = styled.p`
	${fontSurt};
	font-size: 10px;
	opacity: 0.4;
	grid-column: 5 / -3;
	text-align: center;
	${spacing("marginTop", "s")};

	a {
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	}

	@media ${screen("md")} {
		grid-column: 6 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 11 / -10;
	}

	@media ${screen("mx")} {
		grid-column: 17 / -16;
	}
`;

const tickLabelOffset = 10;

const xAccessor = (d) => parseInt(d.year);

export default function Graph({
	data,
	title,
	keys = ["corporate", "employee", "gdp"],
	labels = {
		corporate: "Corporate profit",
		gdp: "GDP",
		employee: "Employee income",
	},
}) {
	const [highlightedLine, setHighlightedLine] = useState(null);
	const [numTicks, setNumTicks] = useState(5);
	const area = useGetResizer();
	const colors = { gdp: "#E84115", corporate: "#414BB2", employee: "#004C3B" };
	const customTheme = {
		...lightTheme,
		// order should match XYChart child series render order
		colors: keys.map((key) => colors[key]),
	};

	useEffect(() => {
		setNumTicks(Math.min(Math.floor(window.innerWidth / 70), 10));
	}, [area]);

	return (
		<Container>
			<H3 dangerouslySetInnerHTML={{ __html: title }} />
			<GraphContainer>
				<Legend>
					{keys.map((key) => {
						return (
							<LegendItem
								key={key}
								color={colors[key]}
								onMouseOver={() => {
									setHighlightedLine(key);
								}}
								onMouseOut={() => {
									setHighlightedLine(null);
								}}>
								{labels[key].toUpperCase()}
							</LegendItem>
						);
					})}
				</Legend>
				<XYChart
					theme={customTheme}
					height={600}
					margin={{ left: 65, top: 35, bottom: 75, right: 27 }}
					xScale={{ type: "point" }}
					yScale={{ type: "linear" }}>
					<Grid
						columns={true}
						numTicks={4}
						lineStyle={{
							stroke: "#767676",
							strokeLinecap: "round",
							strokeWidth: 1,
						}}
						strokeDasharray="0, 4"
					/>
					<Axis
						hideAxisLine
						hideTicks
						orientation="bottom"
						tickLabelProps={() => ({ dy: tickLabelOffset })}
						label={"YEARS"}
						labelOffset={45}
						numTicks={numTicks}
					/>
					<Axis
						hideAxisLine
						hideTicks
						tickFormat={(v) => `${Math.round(v * 100)}`}
						orientation="left"
						label={"PERCENTAGE GROWTH (%)"}
						labelOffset={30}
						// numTicks={6}
						tickLabelProps={() => ({ dx: -10 })}
					/>
					{keys.map((key) => {
						return (
							<LineSeries
								key={key}
								stroke={colors[key]}
								opacity={
									highlightedLine !== null && highlightedLine !== key ? 0.3 : 1
								}
								dataKey={key}
								data={data}
								yAccessor={(d) => parseFloat(d[key])}
								xAccessor={xAccessor}
								curve={curveNatural}
							/>
						);
					})}

					<Tooltip
						snapTooltipToDatumX
						snapTooltipToDatumY
						showSeriesGlyphs
						renderTooltip={({ tooltipData }) => {
							return (
								<TooltipContainer>
									<TooltipDate>
										{tooltipData.nearestDatum.datum.year}
									</TooltipDate>
									{Object.entries(tooltipData.datumByKey).map(
										(lineDataArray) => {
											const [key, value] = lineDataArray;

											return (
												<div className="row" key={key}>
													<div className="value">
														<ColoredSquare color={colors[key]} />
														{Math.round(value.datum[key] * 100 * 100) / 100}%
													</div>
												</div>
											);
										}
									)}
								</TooltipContainer>
							);
						}}
					/>
				</XYChart>
			</GraphContainer>
			<Attribution>
				<a
					href="https://www.rug.nl/ggdc/historicaldevelopment/maddison/releases/maddison-project-database-2020"
					target="_blank"
					rel="noreferrer">
					Maddison Project Database
				</a>
				,{" "}
				<a
					href="https://www.nytimes.com/2018/07/13/business/economy/wages-workers-profits.html"
					target="_blank"
					rel="noreferrer">
					NYTimes
				</a>
				,{" "}
				<a
					href="https://ourworldindata.org/grapher/gdp-per-capita-maddison-2020?time=1950..2016&country=USA~AUS~CAN~FRA~SWE~DNK~GBR~JPN~KOR"
					target="_blank"
					rel="noreferrer">
					OurWorldInData
				</a>
			</Attribution>
		</Container>
	);
}
