import React, { useRef, useState, useEffect } from "react";
import Scene from "./GlobalProtests/Scene";
import Title from "./GlobalProtests/Title";
import { useGetResizer } from "../../../contexts/Resizer";
import styled from "@emotion/styled";

const ProtestsContainer = styled.div`
	position: relative;
	z-index: -1;
`;

export default function GlobalProtests(props) {
	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);

	useEffect(() => {
		const refPosition = ref.current?.getBoundingClientRect();
		const top = refPosition.top + window.pageYOffset;
		setRefTop(top);
	}, [area]);

	return (
		<ProtestsContainer ref={ref}>
			<Title title={props.title} containerTop={refTop} />
			<Scene {...props} />
		</ProtestsContainer>
	);
}
