import React, { memo } from "react";
import housePrices from "./data/house-prices";
import styled from "@emotion/styled";
import { fontRoslindaleDisplay, fontSurt } from "../../../../styles/typography";
import ClickForMoreInfo from "./ClickForMoreInfo";
import { useDefinitionModal } from "../../../../contexts/DefinitionModalContext";
import front from "./images/card-front.png";
import { css } from "@emotion/react";
const Card = styled.div`
	width: 360px;
	height: 520px;
	background-color: #f0f0f0;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 50px 55px 35px 55px;
	position: relative;
	cursor: pointer;
	background-image: url(${front});
	background-size: cover;
`;
const Prefix = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 20px;
	font-size: 10px;
	${fontSurt};
	font-weight: 600;
	letter-spacing: 0.05em;
`;
const Title = styled.h4`
	font-size: 18px;
	${fontRoslindaleDisplay};
	margin-bottom: 10px;
	font-weight: 586;
`;
const HouseImage = styled.svg`
	margin-bottom: 14px;
`;
const Content = styled.div`
	font-size: 13px;
`;
export default memo(function House({ state }) {
	let houseLocation = housePrices.find((p) => p.cost * 0.2 < state.wealth);
	const { setContentKey, setActive } = useDefinitionModal();
	return (
		<Card
			onClick={() => {
				setContentKey("housing");
				setActive(true);
			}}>
			<Prefix>HOUSE CARD</Prefix>
			{houseLocation ? (
				<HouseImage
					width="60"
					height="61"
					viewBox="0 0 60 61"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0)">
						<path
							d="M1 29.5L18 15.5L35 38.5V60.5L1 51.5V29.5Z"
							fill="#1E1E1E"
						/>
						<path d="M35 38.5L59 27.5V49.5L35 60.5V38.5Z" fill="#474747" />
						<path d="M18 15.5L43 4.5L59 27.5L35 38.5L18 15.5Z" fill="#626262" />
						<path d="M32 5.5V15.5L39 12.5V2.5L32 5.5Z" fill="#474747" />
						<path d="M24 3.5L32 5.5L39 2.5L31 0.5L24 3.5Z" fill="#626262" />
						<path d="M24 13V3.5L32 5.5V15.5L28 11.5L24 13Z" fill="#1E1E1E" />
					</g>
					<defs>
						<clipPath id="clip0">
							<rect
								width="60"
								height="60"
								fill="white"
								transform="translate(0 0.5)"
							/>
						</clipPath>
					</defs>
				</HouseImage>
			) : (
				<HouseImage
					width="60"
					height="61"
					viewBox="0 0 60 61"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0)">
						<path d="M15 32.5L40 21.5L30 43.5L5 54.5L15 32.5Z" fill="#1E1E1E" />
						<path d="M15 24.5H16V56.5H15V24.5Z" fill="#626262" />
						<path d="M39 15.5H40V47.5H39V15.5Z" fill="#626262" />
						<path
							d="M15 32.5L40 21.5L54 49.5L29 60.5L15 32.5Z"
							fill="#626262"
						/>
					</g>
					<defs>
						<clipPath id="clip0">
							<rect
								width="60"
								height="60"
								fill="white"
								transform="translate(0 0.5)"
							/>
						</clipPath>
					</defs>
				</HouseImage>
			)}

			{houseLocation && (
				<Title>
					YOU CAN AFFORD
					<br /> A TWO-BEDROOM APARTMENT IN{" "}
					{houseLocation.country.toUpperCase()}`
				</Title>
			)}
			{!houseLocation && (
				<Title>YOU CAN’T AFFORD A TWO-BEDROOM APARTMENT</Title>
			)}

			<Content>
				{!houseLocation &&
					"With your current wealth, you can’t afford a two-bedroom apartment anywhere in the world."}
				{houseLocation &&
					`With your current wealth, you can afford a two-bedroom apartment in ${houseLocation.country}`}
			</Content>
			<ClickForMoreInfo
				css={css`
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 18px;
				`}>
				WHY THIS IS IMPORTANT
			</ClickForMoreInfo>
		</Card>
	);
});
