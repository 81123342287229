import React from "react";
import styled from "@emotion/styled";
import { fontRoslindaleDisplay } from "../../../../styles/typography";
const Container = styled.div``;
const Title = styled.h5`
	${fontRoslindaleDisplay};
	font-size: 25px;
	margin-bottom: 14px;
`;
const Content = styled.div`
	p {
		margin-bottom: 14px;
		font-size: 18px;
	}
`;
export default function GraphIntroduction() {
	return (
		<Container>
			<Title>The Life Cycle</Title>
			<Content>
				<p>
					The graph on the left records the total wealth of your character as
					you progress through a life from age 18 to retirement at 65. Its
					course will be influenced by your income, expenses and interest rates,
					which for simplicity we assume to be fixed.
				</p>
				<p>
					Every decade, you’ll draw a Chance Card. These cards are randomly
					assigned. Scroll to discover where you end up at retirement, given the
					hand you must play..
				</p>
			</Content>
		</Container>
	);
}
