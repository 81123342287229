import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useGetResizer } from "../../../../contexts/Resizer";

import Domino from "./Domino";
import { useViewportScroll, useTransform, useSpring } from "framer-motion";
import { ratio } from "../../../../styles/mixins";
import { colours } from "../../../../styles/colours";

const Visualisation = styled.div`
	width: 100%;
	position: relative;
	z-index: 0;

	${ratio("paddingBottom", 0, {
		mn: {
			base: 1.25 * 375,
			ratio: 1,
		},
		lg: {
			base: 0.75 * 1440,
			ratio: 1,
		},
		mx: {
			base: 0.75 * 2560,
			ratio: 1,
		},
	})}
`;

const HeightWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding-bottom: 150%;
	width: 100%;
	background-color: ${colours.bgWhite};

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 100%
		);
	}
`;

const DominosWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	perspective: 10000px;
`;

export default function DominosBottom() {
	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);
	const [refBottom, setRefBottom] = useState(1);
	const [windowHeight, setWindowHeight] = useState(768);
	const [windowWidth, setWindowWidth] = useState(1024);

	const { scrollY } = useViewportScroll();

	const progress = useSpring(
		useTransform(
			scrollY,
			[refTop - windowHeight, refBottom + windowHeight],
			[0, 1]
		),
		{
			stiffness: 1000,
			damping: 50,
		}
	);

	useEffect(() => {
		const refPosition = ref.current?.getBoundingClientRect();
		const top = refPosition.top + window.pageYOffset;
		const bottom = top + refPosition.height - window.innerHeight;
		setRefTop(top);
		setRefBottom(bottom);
	}, [area]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
			updateWindowWidth();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		const updateWindowWidth = () => {
			setWindowWidth(window.innerWidth);
		};

		updateWindowHeight();
		updateWindowWidth();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Visualisation>
			<HeightWrapper ref={ref}>
				<DominosWrapper>
					<Domino
						progress={progress}
						left={"-50%"}
						top={"2%"}
						rotateInput={[0, 0.1, 0.25, 1.25]}
						rotateXOutput={[-5, -25, -30, -55]}
						rotateYOutput={[30, 30, 30, 30]}
						rotateZOutput={[5, 5, 5, 5]}
						zInput={[0, 1]}
						zOutput={[-0.35 * windowWidth, -0.35 * windowWidth]}
						xInput={[0, 1]}
						xOutput={[0, 0]}
						size={windowWidth * 0.417}
					/>
					<Domino
						progress={progress}
						left={"-30%"}
						top={"10%"}
						rotateInput={[0.1, 0.25, 1.25]}
						rotateXOutput={[-10, -25, -45]}
						rotateYOutput={[20, 20, 20]}
						rotateZOutput={[10, 10, 10]}
						zInput={[0, 1]}
						zOutput={[-0.25 * windowWidth, -0.25 * windowWidth]}
						xInput={[0, 1]}
						xOutput={[0, 0]}
						size={windowWidth * 0.625}
					/>
					<Domino
						progress={progress}
						left={"-50%"}
						top={"20%"}
						rotateInput={[0.25, 1.25]}
						rotateXOutput={[-15, -60]}
						rotateYOutput={[10, 10]}
						rotateZOutput={[15, 15]}
						zInput={[0, 1]}
						zOutput={[0, 0]}
						xInput={[0, 1]}
						xOutput={[0, 0]}
						size={windowWidth * 1.111}
					/>
				</DominosWrapper>
			</HeightWrapper>
		</Visualisation>
	);
}
