const housePrices = [
	{ country: "Hong Kong, Hong Kong", cost: 2397681.75 },
	{ country: "Seoul, South Korea", cost: 1509885.75 },
	{ country: "Queens, NY, United States", cost: 1389115.5 },
	{ country: "Singapore, Singapore", cost: 1384605 },
	{ country: "Paris, France", cost: 1245864 },
	{ country: "Beijing, China", cost: 1212924 },
	{ country: "Shenzhen, China", cost: 1180342.5 },
	{ country: "Shanghai, China", cost: 1179215.25 },
	{ country: "London, United Kingdom", cost: 1167072 },
	{ country: "New York, NY, United States", cost: 1161168.75 },
	{ country: "Geneva, Switzerland", cost: 1107660.75 },
	{ country: "Zurich, Switzerland", cost: 1096424.25 },
	{ country: "Munich, Germany", cost: 1080849.75 },
	{ country: "Tel Aviv-Yafo, Israel", cost: 1062469.5 },
	{ country: "Santa Barbara, CA, United States", cost: 1036209.7499999999 },
	{ country: "Basel, Switzerland", cost: 1028207.25 },
	{ country: "San Francisco, CA, United States", cost: 1021837.5 },
	{ country: "Luxembourg, Luxembourg", cost: 1020846.7500000001 },
	{ country: "Macao, Macao", cost: 1009862.25 },
	{ country: "Brooklyn, NY, United States", cost: 992970.75 },
	{ country: "Taipei, Taiwan", cost: 960360 },
	{ country: "Boulder, CO, United States", cost: 956404.5 },
	{ country: "Fremont, CA, United States", cost: 955683 },
	{ country: "West Palm Beach, FL, United States", cost: 952182 },
	{ country: "Lugano, Switzerland", cost: 935249.25 },
	{ country: "Boston, MA, United States", cost: 928050.75 },
	{ country: "Stockholm, Sweden", cost: 897070.5 },
	{ country: "Tokyo, Japan", cost: 877973.25 },
	{ country: "Jersey City, NJ, United States", cost: 871320.75 },
	{ country: "Sydney, Australia", cost: 828691.5 },
	{ country: "Oakland, CA, United States", cost: 791565 },
	{ country: "Guangzhou, China", cost: 780759.0000000001 },
	{ country: "Oslo, Norway", cost: 768615 },
	{ country: "Berkeley, CA, United States", cost: 757002 },
	{ country: "Helsinki, Finland", cost: 753288.75 },
	{ country: "Milan, Italy", cost: 751770 },
	{ country: "Vancouver, Canada", cost: 716273.25 },
	{ country: "Toronto, Canada", cost: 714684.7499999999 },
	{ country: "San Jose, CA, United States", cost: 708906 },
	{ country: "Vienna, Austria", cost: 704565.7499999999 },
	{ country: "Dubrovnik, Croatia", cost: 687676.5 },
	{ country: "Amsterdam, Netherlands", cost: 687119.25 },
	{ country: "Frankfurt, Germany", cost: 678189.75 },
	{ country: "Jerusalem, Israel", cost: 677517 },
	{ country: "Hamburg, Germany", cost: 662624.25 },
	{ country: "Auckland, New Zealand", cost: 662181 },
	{ country: "Bern, Switzerland", cost: 658374 },
	{ country: "Saint Helier, Jersey", cost: 644036.25 },
	{ country: "Fort Collins, CO, United States", cost: 640705.5 },
	{ country: "Freiburg im Breisgau, Germany", cost: 638466.75 },
	{ country: "Los Angeles, CA, United States", cost: 638406 },
	{ country: "Hangzhou, China", cost: 638109.7499999999 },
	{ country: "Markham, Canada", cost: 633813 },
	{ country: "Nice, France", cost: 623452.5 },
	{ country: "North Vancouver, Canada", cost: 620131.5 },
	{ country: "Cambridge, United Kingdom", cost: 619217.25 },
	{ country: "Rome, Italy", cost: 618058.5 },
	{ country: "Copenhagen, Denmark", cost: 613512 },
	{ country: "Brighton, United Kingdom", cost: 609164.25 },
	{ country: "Innsbruck, Austria", cost: 588408 },
	{ country: "Glasgow, United Kingdom", cost: 586311.75 },
	{ country: "Haarlem, Netherlands", cost: 586297.5 },
	{ country: "Salzburg, Austria", cost: 582509.25 },
	{ country: "Berlin, Germany", cost: 569450.25 },
	{ country: "Gothenburg, Sweden", cost: 564916.5 },
	{ country: "Long Beach, CA, United States", cost: 562627.5 },
	{ country: "Kuwait City, Kuwait", cost: 559953 },
	{ country: "Melbourne, Australia", cost: 559476.75 },
	{ country: "Honolulu, HI, United States", cost: 558243 },
	{ country: "Seattle, WA, United States", cost: 554658 },
	{ country: "Sacramento, CA, United States", cost: 549831.75 },
	{ country: "Washington, DC, United States", cost: 547167 },
	{ country: "Stuttgart, Germany", cost: 545305.5 },
	{ country: "Tacoma, WA, United States", cost: 539620.5 },
	{ country: "Nanjing, China", cost: 539195.25 },
	{ country: "Tromso, Norway", cost: 538521 },
	{ country: "Espoo, Finland", cost: 538275.75 },
	{ country: "Surrey, Canada", cost: 535854 },
	{ country: "Mississauga, Canada", cost: 535397.25 },
	{ country: "Liverpool, United Kingdom", cost: 532837.5 },
	{ country: "Bonn, Germany", cost: 529303.5 },
	{ country: "Trondheim, Norway", cost: 529128.75 },
	{ country: "Bellevue, WA, United States", cost: 523812 },
	{ country: "Cologne, Germany", cost: 522560.25 },
	{ country: "Salt Lake City, UT, United States", cost: 516407.99999999994 },
	{ country: "Darmstadt, Germany", cost: 513653.25 },
	{ country: "Dublin, Ireland", cost: 507089.24999999994 },
	{ country: "Mumbai, India", cost: 505501.50000000006 },
	{ country: "Bergen, Norway", cost: 500787 },
	{ country: "Prague, Czech Republic", cost: 498873 },
	{ country: "San Diego, CA, United States", cost: 491394 },
	{ country: "Bristol, United Kingdom", cost: 490041 },
	{ country: "Cascais, Portugal", cost: 488202 },
	{ country: "Dusseldorf, Germany", cost: 487335 },
	{ country: "Valletta, Malta", cost: 480684.75 },
	{ country: "Stavanger, Norway", cost: 480668.25 },
	{ country: "Mesa, AZ, United States", cost: 478155 },
	{ country: "Augsburg, Germany", cost: 478140 },
	{ country: "Lund, Sweden", cost: 476180.25 },
	{ country: "Antibes, France", cost: 470135.25 },
	{ country: "Victoria, Canada", cost: 469716.75 },
	{ country: "Heidelberg, Germany", cost: 467913.75 },
	{ country: "Arhus, Denmark", cost: 461190 },
	{ country: "Uppsala, Sweden", cost: 459570.75 },
	{ country: "Lyon, France", cost: 457122.75 },
	{ country: "Madrid, Spain", cost: 456731.25 },
	{ country: "Nuremberg, Germany", cost: 450642.00000000006 },
	{ country: "Utrecht, Netherlands", cost: 450504 },
	{ country: "Coquitlam, Canada", cost: 447288.75 },
	{ country: "Brampton, Canada", cost: 445434 },
	{ country: "Barcelona, Spain", cost: 440946 },
	{ country: "Florence, Italy", cost: 436687.5 },
	{ country: "Moscow, Russia", cost: 436389.75 },
	{ country: "Erlangen, Germany", cost: 435735 },
	{ country: "Asheville, NC, United States", cost: 427768.5 },
	{ country: "Turku, Finland", cost: 426541.5 },
	{ country: "Tampere, Finland", cost: 424596 },
	{ country: "Irvine, CA, United States", cost: 421255.5 },
	{ country: "Fort Lauderdale, FL, United States", cost: 418548.75 },
	{ country: "Aachen, Germany", cost: 418305.75 },
	{ country: "Edinburgh, United Kingdom", cost: 416596.5 },
	{ country: "Lisbon, Portugal", cost: 416011.5 },
	{ country: "Charleston, SC, United States", cost: 413628 },
	{ country: "Bangkok, Thailand", cost: 410219.25 },
	{ country: "Brisbane, Australia", cost: 409260.75000000006 },
	{ country: "Toulouse, France", cost: 409012.5 },
	{ country: "Sliema, Malta", cost: 407559.75 },
	{ country: "Bilbao, Spain", cost: 403628.25 },
	{ country: "Spokane, WA, United States", cost: 398309.25 },
	{ country: "Wellington, New Zealand", cost: 397359 },
	{ country: "Karlsruhe, Germany", cost: 397338 },
	{ country: "Aalborg, Denmark", cost: 396227.25 },
	{ country: "Hanover, Germany", cost: 396198 },
	{ country: "Manchester, United Kingdom", cost: 394660.5 },
	{ country: "Rehovot, Israel", cost: 391347 },
	{ country: "Perth, Australia", cost: 389895.75 },
	{ country: "Denver, CO, United States", cost: 389871.75 },
	{ country: "Atlanta, GA, United States", cost: 388941.75 },
	{ country: "Nantes, France", cost: 386982 },
	{ country: "Graz, Austria", cost: 383243.25 },
	{ country: "Rotterdam, Netherlands", cost: 379563.75 },
	{ country: "Birmingham, United Kingdom", cost: 377659.5 },
	{ country: "Canberra, Australia", cost: 375162 },
	{ country: "Linz, Austria", cost: 375051 },
	{ country: "Reykjavik, Iceland", cost: 375023.25000000006 },
	{ country: "Palma de Mallorca, Spain", cost: 373487.25 },
	{ country: "Malmo, Sweden", cost: 372978.75 },
	{ country: "Southampton, United Kingdom", cost: 372408.75 },
	{ country: "Kaohsiung, Taiwan", cost: 369709.5 },
	{ country: "Austin, TX, United States", cost: 368458.5 },
	{ country: "Delft, Netherlands", cost: 365787.75 },
	{ country: "Miami, FL, United States", cost: 364968 },
	{ country: "Montreal, Canada", cost: 364625.25 },
	{ country: "Eindhoven, Netherlands", cost: 364294.5 },
	{ country: "Haifa, Israel", cost: 362761.5 },
	{ country: "Sarasota, FL, United States", cost: 358300.5 },
	{ country: "Chicago, IL, United States", cost: 358080.75 },
	{ country: "Warsaw, Poland", cost: 357174 },
	{ country: "New Orleans, LA, United States", cost: 353722.5 },
	{ country: "Bologna, Italy", cost: 353704.50000000006 },
	{ country: "Bremen, Germany", cost: 353175 },
	{ country: "Aberdeen, United Kingdom", cost: 352822.5 },
	{ country: "Naples, Italy", cost: 350936.25 },
	{ country: "Bergamo, Italy", cost: 347124 },
	{ country: "Minneapolis, MN, United States", cost: 346149 },
	{ country: "Bratislava, Slovakia", cost: 343946.25 },
	{ country: "Wuhan, China", cost: 341952.75 },
	{ country: "Ljubljana, Slovenia", cost: 341729.25 },
	{ country: "Philadelphia, PA, United States", cost: 341507.25 },
	{ country: "Mannheim, Germany", cost: 340517.24999999994 },
	{ country: "Hamilton, Canada", cost: 340310.99999999994 },
	{ country: "Doha, Qatar", cost: 336492.00000000006 },
	{ country: "Vancouver, WA, United States", cost: 335162.25 },
	{ country: "Montpellier, France", cost: 334395.75 },
	{ country: "Brussels, Belgium", cost: 333813.75 },
	{ country: "Boise, ID, United States", cost: 331671 },
	{ country: "The Hague (Den Haag), Netherlands", cost: 327954.74999999994 },
	{ country: "Makati, Philippines", cost: 327721.5 },
	{ country: "Santa Rosa, CA, United States", cost: 327660 },
	{ country: "Portland, OR, United States", cost: 323795.25000000006 },
	{ country: "Gdansk, Poland", cost: 321114.00000000006 },
	{ country: "Leipzig, Germany", cost: 321068.25 },
	{ country: "Ottawa, Canada", cost: 317670 },
	{ country: "Kitchener, Canada", cost: 317017.5 },
	{ country: "Brno, Czech Republic", cost: 316535.25 },
	{ country: "Rennes, France", cost: 315151.50000000006 },
	{ country: "Richmond, VA, United States", cost: 313058.25 },
	{ country: "Split, Croatia", cost: 311955.75 },
	{ country: "Krakow (Cracow), Poland", cost: 310944 },
	{ country: "Dresden, Germany", cost: 310590 },
	{ country: "Halifax, Canada", cost: 307533.75 },
	{ country: "Nijmegen, Netherlands", cost: 305733.75 },
	{ country: "Chengdu, China", cost: 305319 },
	{ country: "Parma, Italy", cost: 303867.75 },
	{ country: "Cork, Ireland", cost: 302369.25 },
	{ country: "Verona, Italy", cost: 301192.5 },
	{ country: "Las Palmas de Gran Canaria, Spain", cost: 299052 },
	{ country: "Beirut, Lebanon", cost: 298647 },
	{ country: "Oulu, Finland", cost: 298134.75 },
	{ country: "Baton Rouge, LA, United States", cost: 295254 },
	{ country: "Lille, France", cost: 294141.75 },
	{ country: "Malaga, Spain", cost: 290844.75 },
	{ country: "Marseille, France", cost: 289269.75 },
	{ country: "Nashville, TN, United States", cost: 287936.25 },
	{ country: "Turin, Italy", cost: 284616 },
	{ country: "Wroclaw, Poland", cost: 283588.5 },
	{ country: "Gent, Belgium", cost: 281646.75 },
	{ country: "Grenoble, France", cost: 280704.75 },
	{ country: "Genoa, Italy", cost: 279188.25 },
	{ country: "Raleigh, NC, United States", cost: 278831.25 },
	{ country: "Tehran, Iran", cost: 278820 },
	{ country: "Groningen, Netherlands", cost: 278612.25 },
	{ country: "Columbus, OH, United States", cost: 278328 },
	{ country: "Ho Chi Minh City, Vietnam", cost: 276095.25 },
	{ country: "Christchurch, New Zealand", cost: 275536.5 },
	{ country: "Adelaide, Australia", cost: 272099.25 },
	{ country: "Budapest, Hungary", cost: 271413.75 },
	{ country: "Dubai, United Arab Emirates", cost: 271105.5 },
	{ country: "Tampa, FL, United States", cost: 270114 },
	{ country: "Poznan, Poland", cost: 268721.25 },
	{ country: "Charlotte, NC, United States", cost: 266812.5 },
	{ country: "Zagreb, Croatia", cost: 266553.75 },
	{ country: "Hobart, Australia", cost: 265809.75 },
	{ country: "Madison, WI, United States", cost: 263301 },
	{ country: "Gdynia, Poland", cost: 262559.25 },
	{ country: "Antwerp, Belgium", cost: 262460.25 },
	{ country: "Vilnius, Lithuania", cost: 261842.99999999997 },
	{ country: "Manila, Philippines", cost: 260243.25 },
	{ country: "Phoenix, AZ, United States", cost: 260065.5 },
	{ country: "Tallinn, Estonia", cost: 254400 },
	{ country: "Eugene, OR, United States", cost: 254040.75 },
	{ country: "Calgary, Canada", cost: 253740.75 },
	{ country: "Everett, WA, United States", cost: 252283.50000000003 },
	{ country: "Seville (Sevilla), Spain", cost: 252267.75 },
	{ country: "Kigali, Rwanda", cost: 252039 },
	{ country: "Valencia, Spain", cost: 250485.75 },
	{ country: "Saint Paul, MN, United States", cost: 248915.25 },
	{ country: "Kelowna, Canada", cost: 248553.75 },
	{ country: "Colorado Springs, CO, United States", cost: 248081.25 },
	{ country: "Porto, Portugal", cost: 245273.25 },
	{ country: "Salem, OR, United States", cost: 242865 },
	{ country: "Fort Worth, TX, United States", cost: 242778 },
	{ country: "Limassol, Cyprus", cost: 242517.75 },
	{ country: "Reno, NV, United States", cost: 242017.5 },
	{ country: "Alicante, Spain", cost: 240555 },
	{ country: "Bari, Italy", cost: 240036.74999999997 },
	{ country: "Cagliari, Italy", cost: 239787 },
	{ country: "Belgrade, Serbia", cost: 239560.5 },
	{ country: "London, Canada", cost: 235856.25 },
	{ country: "Jacksonville, FL, United States", cost: 233868.75 },
	{ country: "Abu Dhabi, United Arab Emirates", cost: 233144.25 },
	{ country: "Nairobi, Kenya", cost: 232558.50000000003 },
	{ country: "Pittsburgh, PA, United States", cost: 227453.99999999997 },
	{ country: "Plzen, Czech Republic", cost: 225762.75 },
	{ country: "Trieste, Italy", cost: 225699.75 },
	{ country: "Dallas, TX, United States", cost: 225545.25 },
	{ country: "Anchorage, AK, United States", cost: 225406.5 },
	{ country: "Palermo, Italy", cost: 224174.24999999997 },
	{ country: "Granada, Spain", cost: 223983 },
	{ country: "Ann Arbor, MI, United States", cost: 223735.5 },
	{ country: "Brescia, Italy", cost: 221646.75 },
	{ country: "Buenos Aires, Argentina", cost: 221080.49999999997 },
	{ country: "Limerick, Ireland", cost: 218859.75 },
	{ country: "Saint Petersburg, Russia", cost: 214380.75 },
	{ country: "Padova, Italy", cost: 214170.75 },
	{ country: "Orlando, FL, United States", cost: 214011.74999999997 },
	{ country: "Durham, NC, United States", cost: 212015.25 },
	{ country: "Kansas City, MO, United States", cost: 211437 },
	{ country: "Manama, Bahrain", cost: 209524.5 },
	{ country: "Edmonton, Canada", cost: 206518.5 },
	{ country: "Houston, TX, United States", cost: 206010 },
	{ country: "Des Moines, IA, United States", cost: 205879.5 },
	{ country: "Kosice, Slovakia", cost: 205737.75 },
	{ country: "Navi Mumbai, India", cost: 205697.25 },
	{ country: "Kuala Lumpur, Malaysia", cost: 205563 },
	{ country: "Knoxville, TN, United States", cost: 204783 },
	{ country: "Cincinnati, OH, United States", cost: 204591 },
	{ country: "Jakarta, Indonesia", cost: 203922 },
	{ country: "Santiago, Chile", cost: 203874 },
	{ country: "Coimbra, Portugal", cost: 202488.75 },
	{ country: "Milwaukee, WI, United States", cost: 202285.5 },
	{ country: "Colombo, Sri Lanka", cost: 201687 },
	{ country: "Louisville, KY, United States", cost: 201063.75 },
	{ country: "Oklahoma City, OK, United States", cost: 200286.74999999997 },
	{ country: "Kaunas, Lithuania", cost: 199991.25 },
	{ country: "Olympia, WA, United States", cost: 199883.25 },
	{ country: "San Juan, Puerto Rico", cost: 199240.5 },
	{ country: "Catania, Italy", cost: 198144.75 },
	{ country: "Grand Rapids, MI, United States", cost: 197797.5 },
	{ country: "Baltimore, MD, United States", cost: 197607.00000000003 },
	{ country: "Las Vegas, NV, United States", cost: 197433.75 },
	{ country: "Fresno, CA, United States", cost: 191967.75 },
	{ country: "Cluj-Napoca, Romania", cost: 191144.25 },
	{ country: "Riga, Latvia", cost: 189118.5 },
	{ country: "Cape Town, South Africa", cost: 187916.25 },
	{ country: "Cebu, Philippines", cost: 187847.25 },
	{ country: "Tartu, Estonia", cost: 187128.75 },
	{ country: "Petaling Jaya, Malaysia", cost: 186387 },
	{ country: "Albuquerque, NM, United States", cost: 185511 },
	{ country: "Tucson, AZ, United States", cost: 185242.5 },
	{ country: "Olomouc, Czech Republic", cost: 185204.25 },
	{ country: "Kota Kinabalu, Malaysia", cost: 185203.5 },
	{ country: "Belfast, United Kingdom", cost: 184172.25 },
	{ country: "Thane, India", cost: 183425.25 },
	{ country: "Delhi, India", cost: 182927.25000000003 },
	{ country: "Santa Cruz de Tenerife, Spain", cost: 182697 },
	{ country: "Bydgoszcz, Poland", cost: 181656.75 },
	{ country: "Mexico City, Mexico", cost: 178486.5 },
	{ country: "Montevideo, Uruguay", cost: 177432.75 },
	{ country: "Lodz, Poland", cost: 175650.75000000003 },
	{ country: "Tulsa, OK, United States", cost: 174503.99999999997 },
	{ country: "Pattaya, Thailand", cost: 171567 },
	{ country: "Bucharest, Romania", cost: 170809.5 },
	{ country: "Katowice, Poland", cost: 170538.75 },
	{ country: "Vladivostok, Russia", cost: 169160.24999999997 },
	{ country: "Rijeka, Croatia", cost: 169085.24999999997 },
	{ country: "Rzeszow, Poland", cost: 169041.75 },
	{ country: "Muscat, Oman", cost: 168924.75 },
	{ country: "Novi Sad, Serbia", cost: 168066.75 },
	{ country: "Maribor, Slovenia", cost: 167553 },
	{ country: "Little Rock, AR, United States", cost: 165660.75 },
	{ country: "Nicosia, Cyprus", cost: 165120.75 },
	{ country: "Indianapolis, IN, United States", cost: 164793.75 },
	{ country: "Panama City, Panama", cost: 164220.75 },
	{ country: "Phnom Penh, Cambodia", cost: 163743.75 },
	{ country: "Saskatoon, Canada", cost: 163119.75 },
	{ country: "Chiang Mai, Thailand", cost: 163005 },
	{ country: "Hanoi, Vietnam", cost: 161627.25000000003 },
	{ country: "Sao Paulo, Brazil", cost: 160059 },
	{ country: "Thessaloniki, Greece", cost: 158615.25 },
	{ country: "Birmingham, AL, United States", cost: 156842.99999999997 },
	{ country: "Tirana, Albania", cost: 156675.75000000003 },
	{ country: "San Antonio, TX, United States", cost: 156447 },
	{ country: "Winnipeg, Canada", cost: 156056.25 },
	{ country: "Casablanca, Morocco", cost: 155841 },
	{ country: "Sofia, Bulgaria", cost: 155357.25 },
	{ country: "Kiev (Kyiv), Ukraine", cost: 154617 },
	{ country: "Lublin, Poland", cost: 154264.5 },
	{ country: "Heraklion, Greece", cost: 152889.75 },
	{ country: "Athens, Greece", cost: 150841.5 },
	{ country: "Minsk, Belarus", cost: 150303.75 },
	{ country: "Sarajevo, Bosnia And Herzegovina", cost: 149327.25 },
	{ country: "Agadir, Morocco", cost: 148974.75 },
	{ country: "Huntsville, AL, United States", cost: 148259.25 },
	{ country: "Accra, Ghana", cost: 148048.5 },
	{ country: "Aveiro, Portugal", cost: 147920.25 },
	{ country: "Saint Louis, MO, United States", cost: 147489 },
	{ country: "Chattanooga, TN, United States", cost: 147453.75 },
	{ country: "Puerto Vallarta, Mexico", cost: 146971.5 },
	{ country: "Chennai, India", cost: 146109.75 },
	{ country: "Wichita, KS, United States", cost: 145750.5 },
	{ country: "Gurgaon, India", cost: 145459.5 },
	{ country: "Brasilia, Brazil", cost: 144897.75 },
	{ country: "Chandigarh, India", cost: 144129 },
	{ country: "Penang, Malaysia", cost: 142553.25 },
	{ country: "Quezon City, Philippines", cost: 142530 },
	{ country: "Baghdad, Iraq", cost: 142063.5 },
	{ country: "Lexington, KY, United States", cost: 141948 },
	{ country: "El Paso, TX, United States", cost: 140952.75 },
	{ country: "Szczecin, Poland", cost: 140478.75 },
	{ country: "Surabaya, Indonesia", cost: 139924.5 },
	{ country: "Podgorica, Montenegro", cost: 139219.5 },
	{ country: "Algiers, Algeria", cost: 139113 },
	{ country: "Sharjah, United Arab Emirates", cost: 139023 },
	{ country: "Ostrava, Czech Republic", cost: 137985.75 },
	{ country: "Buffalo, NY, United States", cost: 137838 },
	{ country: "San Jose, Costa Rica", cost: 137715 },
	{ country: "Lima, Peru", cost: 136911 },
	{ country: "Rabat, Morocco", cost: 136247.25 },
	{ country: "Braga, Portugal", cost: 135880.5 },
	{ country: "Pune, India", cost: 135272.25 },
	{ country: "Bialystok, Poland", cost: 134672.25 },
	{ country: "Bangalore, India", cost: 134433 },
	{ country: "Bogota, Colombia", cost: 133304.25 },
	{ country: "Rio de Janeiro, Brazil", cost: 132433.5 },
	{ country: "Debrecen, Hungary", cost: 132405.75 },
	{ country: "Greensboro, NC, United States", cost: 131798.25 },
	{ country: "Damascus, Syria", cost: 131548.5 },
	{ country: "Yerevan, Armenia", cost: 131226 },
	{ country: "Tangier, Morocco", cost: 130884.75000000001 },
	{ country: "Banja Luka, Bosnia And Herzegovina", cost: 129958.5 },
	{ country: "Iasi, Romania", cost: 129294.75 },
	{ country: "Timisoara, Romania", cost: 128876.99999999999 },
	{ country: "Szeged, Hungary", cost: 128446.49999999999 },
	{ country: "Patras, Greece", cost: 128244 },
	{ country: "Omaha, NE, United States", cost: 127754.25000000001 },
	{ country: "Brasov, Romania", cost: 127299.75 },
	{ country: "Craiova, Romania", cost: 126174 },
	{ country: "Guatemala City, Guatemala", cost: 124088.25 },
	{ country: "Johor Bahru, Malaysia", cost: 123487.5 },
	{ country: "Belo Horizonte, Brazil", cost: 123320.25 },
	{ country: "Kazan, Russia", cost: 123032.25 },
	{ country: "Baku, Azerbaijan", cost: 119211.75 },
	{ country: "Kanpur, India", cost: 118601.99999999999 },
	{ country: "Cleveland, OH, United States", cost: 117972 },
	{ country: "Osijek, Croatia", cost: 117807.75 },
	{ country: "Sibiu, Romania", cost: 117574.5 },
	{ country: "Ernakulam, India", cost: 117427.5 },
	{ country: "Albany, NY, United States", cost: 114489.75 },
	{ country: "Skopje, North Macedonia", cost: 114489.75 },
	{ country: "Quito, Ecuador", cost: 114204.75 },
	{ country: "Guayaquil, Ecuador", cost: 114166.5 },
	{ country: "Marrakech, Morocco", cost: 112680.75 },
	{ country: "Constanta, Romania", cost: 110744.25 },
	{ country: "Varna, Bulgaria", cost: 109995.74999999999 },
	{ country: "Fortaleza, Brazil", cost: 108804 },
	{ country: "Dhaka, Bangladesh", cost: 107780.25 },
	{ country: "Larissa, Greece", cost: 107008.5 },
	{ country: "Nis, Serbia", cost: 105214.49999999999 },
	{ country: "Bandung, Indonesia", cost: 104849.25 },
	{ country: "Yekaterinburg, Russia", cost: 104631.75 },
	{ country: "Amman, Jordan", cost: 103191.00000000001 },
	{ country: "Addis Ababa, Ethiopia", cost: 103130.25 },
	{ country: "Rochester, NY, United States", cost: 103106.25 },
	{ country: "Florianopolis, Brazil", cost: 102686.25 },
	{ country: "Santo Domingo, Dominican Republic", cost: 102537 },
	{ country: "Istanbul, Turkey", cost: 100967.25 },
	{ country: "Novosibirsk, Russia", cost: 100397.25000000001 },
	{ country: "Oradea, Romania", cost: 100298.25 },
	{ country: "Vijayawada, India", cost: 100287 },
	{ country: "Pristina, Kosovo (Disputed Territory)", cost: 98693.25 },
	{ country: "Merida, Mexico", cost: 98463 },
	{ country: "Porto Alegre, Brazil", cost: 97751.25 },
	{ country: "Nizhny Novgorod, Russia", cost: 97401.75 },
	{ country: "Kingston, Jamaica", cost: 97147.5 },
	{ country: "Cuenca, Ecuador", cost: 96145.5 },
	{ country: "Burgas, Bulgaria", cost: 94628.25 },
	{ country: "Kathmandu, Nepal", cost: 94489.49999999999 },
	{ country: "Ufa, Russia", cost: 94474.5 },
	{ country: "Asuncion, Paraguay", cost: 94278.75 },
	{ country: "Tbilisi, Georgia", cost: 92837.25 },
	{ country: "Kolkata, India", cost: 92741.25 },
	{ country: "Almaty, Kazakhstan", cost: 92703.75 },
	{ country: "Plovdiv, Bulgaria", cost: 91501.5 },
	{ country: "Rajkot, India", cost: 90567.75 },
	{ country: "Odessa (Odesa), Ukraine", cost: 90342.75 },
	{ country: "Recife, Brazil", cost: 90122.25000000001 },
	{ country: "San Salvador, El Salvador", cost: 90000 },
	{ country: "Hyderabad, India", cost: 89036.25 },
	{ country: "Tyumen, Russia", cost: 89000.25 },
	{ country: "Riyadh, Saudi Arabia", cost: 88401 },
	{ country: "Campinas, Brazil", cost: 88243.5 },
	{ country: "La Paz, Bolivia", cost: 88049.25 },
	{ country: "Lviv, Ukraine", cost: 87148.5 },
	{ country: "Irkutsk, Russia", cost: 86964 },
	{ country: "Chisinau, Moldova", cost: 86769.75 },
	{ country: "Mysore, India", cost: 86719.5 },
	{ country: "Tashkent, Uzbekistan", cost: 86611.5 },
	{ country: "Zapopan, Mexico", cost: 86568.75 },
	{ country: "Krasnodar, Russia", cost: 85946.25 },
	{ country: "Detroit, MI, United States", cost: 85843.5 },
	{ country: "Indore, India", cost: 85829.25000000001 },
	{ country: "Monterrey, Mexico", cost: 85758 },
	{ country: "Noida, India", cost: 83770.5 },
	{ country: "Tomsk, Russia", cost: 83165.24999999999 },
	{ country: "Curitiba, Brazil", cost: 82796.25 },
	{ country: "Johannesburg, South Africa", cost: 82239.75 },
	{ country: "Memphis, TN, United States", cost: 81915 },
	{ country: "Guadalajara, Mexico", cost: 81479.25000000001 },
	{ country: "Medellin, Colombia", cost: 81219 },
	{ country: "Tijuana, Mexico", cost: 81098.25 },
	{ country: "Santa Cruz, Bolivia", cost: 80914.49999999999 },
	{ country: "Akron, OH, United States", cost: 80164.49999999999 },
	{ country: "Ad Dammam, Saudi Arabia", cost: 79979.25000000001 },
	{ country: "Samara, Russia", cost: 79128 },
	{ country: "Faridabad, India", cost: 77418.75 },
	{ country: "Ahmedabad, India", cost: 77291.25 },
	{ country: "Raipur, India", cost: 77283 },
	{ country: "Kharkiv, Ukraine", cost: 75384 },
	{ country: "Jeddah (Jiddah), Saudi Arabia", cost: 74322 },
	{ country: "As Sulaymaniyah, Iraq", cost: 74254.5 },
	{ country: "Bhubaneswar, India", cost: 74143.5 },
	{ country: "Perm, Russia", cost: 74043 },
	{ country: "Yogyakarta, Indonesia", cost: 73567.5 },
	{ country: "Erbil (Irbil), Iraq", cost: 73548 },
	{ country: "Rostov-na-donu, Russia", cost: 72931.5 },
	{ country: "Arad, Romania", cost: 72517.5 },
	{ country: "Kabul, Afghanistan", cost: 72016.5 },
	{ country: "Dnipro, Ukraine", cost: 71876.25 },
	{ country: "Cancun, Mexico", cost: 71682 },
	{ country: "Ulaanbaatar, Mongolia", cost: 70988.25 },
	{ country: "Krasnoyarsk, Russia", cost: 70965.75 },
	{ country: "Coimbatore, India", cost: 70887.75 },
	{ country: "Bucaramanga, Colombia", cost: 69934.5 },
	{ country: "Astana (Nur-Sultan), Kazakhstan", cost: 69932.25 },
	{ country: "Kaliningrad, Russia", cost: 69391.5 },
	{ country: "Cali, Colombia", cost: 68910 },
	{ country: "Jaipur, India", cost: 68855.25 },
	{ country: "Goa, India", cost: 68623.5 },
	{ country: "Joinville, Brazil", cost: 67547.25 },
	{ country: "Chittagong, Bangladesh", cost: 67448.25 },
	{ country: "Patna, India", cost: 67407.75 },
	{ country: "Tunis, Tunisia", cost: 67296.75 },
	{ country: "Izmir, Turkey", cost: 67009.5 },
	{ country: "Volgograd, Russia", cost: 66804 },
	{ country: "Voronezh, Russia", cost: 66768.75 },
	{ country: "Cairo, Egypt", cost: 66558.75 },
	{ country: "Ranchi, India", cost: 66513 },
	{ country: "Lucknow (Lakhnau), India", cost: 65835 },
	{ country: "Guwahati, India", cost: 65826.75 },
	{ country: "Al Marsa, Tunisia", cost: 65625 },
	{ country: "Kochi, India", cost: 65083.5 },
	{ country: "Lagos, Nigeria", cost: 63930.75 },
	{ country: "Islamabad, Pakistan", cost: 63915 },
	{ country: "Nagpur, India", cost: 63912.75 },
	{ country: "Thiruvananthapuram, India", cost: 63499.5 },
	{ country: "Karachi, Pakistan", cost: 63456.75 },
	{ country: "Mangalore, India", cost: 62308.5 },
	{ country: "Bali, Indonesia", cost: 61695.75 },
	{ country: "Ghaziabad, India", cost: 61576.5 },
	{ country: "Durban, South Africa", cost: 61222.5 },
	{ country: "Alexandria, Egypt", cost: 61221.75 },
	{ country: "Omsk, Russia", cost: 60987.75 },
	{ country: "Batumi, Ajara, Georgia", cost: 60746.25 },
	{ country: "Izhevsk, Russia", cost: 60697.5 },
	{ country: "Goiania, Brazil", cost: 60411 },
	{ country: "Aryanah (Ariana), Tunisia", cost: 59874.00000000001 },
	{ country: "Puebla, Mexico", cost: 59712.75 },
	{ country: "Vinnytsia, Ukraine", cost: 58300.5 },
	{ country: "Barranquilla, Colombia", cost: 57900.75 },
	{ country: "Bishkek, Kyrgyzstan", cost: 56661 },
	{ country: "Saratov, Russia", cost: 56160.74999999999 },
	{ country: "Bhopal, India", cost: 55383.00000000001 },
	{ country: "Visakhapatnam, India", cost: 55009.5 },
	{ country: "Pretoria, South Africa", cost: 54319.5 },
	{ country: "Surat, India", cost: 54305.25000000001 },
	{ country: "Lahore, Pakistan", cost: 53720.25 },
	{ country: "Chelyabinsk, Russia", cost: 53219.25 },
	{ country: "Ternopil, Ukraine", cost: 52590 },
	{ country: "Vadodara, India", cost: 52491 },
	{ country: "Paramaribo, Suriname", cost: 50465.25 },
	{ country: "Ankara, Turkey", cost: 50309.25 },
	{ country: "Queretaro, Mexico", cost: 48157.5 },
	{ country: "Antalya, Turkey", cost: 47904 },
	{ country: "Mykolaiv, Ukraine", cost: 46980 },
	{ country: "Ivano-Frankivsk, Ukraine", cost: 46815 },
	{ country: "Cherkasy, Ukraine", cost: 42359.25 },
	{ country: "Bursa, Turkey", cost: 40530.75 },
	{ country: "Sumy, Ukraine", cost: 37414.5 },
	{ country: "Zaporizhzhya, Ukraine", cost: 34770.75 },
	{ country: "Adana, Turkey", cost: 31015.5 },
	{ country: "Havana, Cuba", cost: 19370.25 },
];

export default housePrices;
