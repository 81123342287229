import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useGetResizer } from "../../../contexts/Resizer";

import {
	useViewportScroll,
	motion,
	useTransform,
	useSpring,
} from "framer-motion";

import { spacing, screen } from "../../../styles/mixins";
import { StaticImage } from "gatsby-plugin-image";
import Parallax from "./Parallax";

const Visualisation = styled.div`
	/* ${spacing("marginTop", "m")}; */
	${spacing("marginBottom", "m")}
	margin-top: -10vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	overflow: hidden;
	z-index: -1;

	@supports (overflow-x: clip) {
		overflow-y: visible;
		overflow-x: clip;
	}

	@media ${screen("mx")} {
		margin-top: -5vw;
	}
`;

const Container = styled.div`
	width: 200%;
	max-width: 2000px;
	position: relative;

	&::before {
		content: "";
		display: block;
		width: 100%;
		padding-bottom: ${`${(2779 / 3600) * 100}%`};
	}
`;

const Illustration = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const House1 = styled(motion.div)`
	position: absolute;
	top: 46.1%;
	left: ${`${(1190 / 3060) * 100}%`};
	width: ${`${(884 / 3060) * 100}%`};
	height: 100%;
	z-index: 7;
`;

const House2 = styled(motion.div)`
	position: absolute;
	top: 40%;
	left: ${`${(1920 / 3060) * 100}%`};
	width: ${`${(586 / 3060) * 100}%`};
	height: 100%;
	z-index: 6;
`;

const House3 = styled(motion.div)`
	position: absolute;
	top: 46.1%;
	left: ${`${(430 / 3060) * 100}%`};
	width: ${`${(568 / 3060) * 100}%`};
	height: 100%;
	z-index: 5;
`;

const House4 = styled(motion.div)`
	position: absolute;
	top: 22%;
	left: ${`${(1940 / 3060) * 100}%`};
	width: ${`${(471 / 3060) * 100}%`};
	height: 100%;
	z-index: 4;
`;

const House5 = styled(motion.div)`
	position: absolute;
	top: 14.9%;
	left: ${`${(830 / 3060) * 100}%`};
	width: ${`${(529 / 3060) * 100}%`};
	height: 100%;
	z-index: 3;
`;

const House6 = styled(motion.div)`
	position: absolute;
	top: 31.5%;
	left: ${`${(1590 / 3060) * 100}%`};
	width: ${`${(236 / 3060) * 100}%`};
	height: 100%;
	z-index: 2;
`;

const House7 = styled(motion.div)`
	position: absolute;
	top: 71%;
	left: ${`${(1005 / 3060) * 100}%`};
	width: ${`${(162 / 3060) * 100}%`};
	height: 100%;
	z-index: 1;
`;

const Man = styled.div`
	position: absolute;
	top: ${`${(2352 / 2615) * 100}%`};
	left: ${`${(890 / 3060) * 100}%`};
	width: ${`${(870 / 3060) * 100}%`};
	height: 100%;
`;

export default function Houses() {
	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);
	const [refBottom, setRefBottom] = useState(1);
	const [windowHeight, setWindowHeight] = useState(768);

	const { scrollY } = useViewportScroll();

	const progress = useSpring(
		useTransform(
			scrollY,
			[refTop - windowHeight, refBottom + windowHeight],
			[0, 1]
		),
		{
			stiffness: 400,
			damping: 90,
		}
	);

	useEffect(() => {
		const refPosition = ref.current?.getBoundingClientRect();
		const top = refPosition.top + window.pageYOffset;
		const bottom = top + refPosition.height - window.innerHeight;
		setRefTop(top);
		setRefBottom(bottom);
	}, [area]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Visualisation ref={ref}>
			<Container>
				<Illustration>
					<Background>
						<StaticImage
							src={"../../../images/houses-bg.jpg"}
							alt={"Background clouds"}
							layout="fullWidth"
							sizes="(min-width: 1530px) 1530px, 200vw"
							placeholder="none"
						/>
					</Background>
					<House1>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.5}>
							<StaticImage
								src={"../../../images/house1.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House1>
					<House2>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.35}>
							<StaticImage
								src={"../../../images/house2.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House2>
					<House3>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.25}>
							<StaticImage
								src={"../../../images/house3.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House3>
					<House4>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.2}>
							<StaticImage
								src={"../../../images/house4.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House4>
					<House5>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.15}>
							<StaticImage
								src={"../../../images/house5.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House5>
					<House6>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.1}>
							<StaticImage
								src={"../../../images/house6.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House6>
					<House7>
						<Parallax
							progress={progress}
							containerHeight={refBottom + windowHeight - refTop}
							parallaxAmount={0.05}>
							<StaticImage
								src={"../../../images/house7.png"}
								alt={"Foating houses"}
								layout="fullWidth"
								sizes="(min-width: 1530px) 921px, 125vw"
								placeholder="none"
							/>
						</Parallax>
					</House7>
					<Man>
						<StaticImage
							src={"../../../images/houses-character.png"}
							alt={"Person looking up at houses"}
							layout="fullWidth"
							sizes="(min-width: 1530px) 435px, 57vw"
							placeholder="none"
						/>
					</Man>
				</Illustration>
			</Container>
		</Visualisation>
	);
}
