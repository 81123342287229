import React, { useRef } from "react";
import styled from "@emotion/styled";
import { motion, useTransform } from "framer-motion";
// import { css } from "@emotion/react";
import { mat4, vec3, mat3 } from "gl-matrix";
import { path as pathHelper } from "d3-path";
import { polygonHull } from "d3-polygon";
// import { transparentize } from "polished";
import diceFace from "./dice face.png";
import diceFace6 from "./dice-face-6.png";

function faceStyles(diceSize, dotSize, face) {
	return {
		front: `translateZ(${diceSize / 2}px)`,
		back: `rotateX(-180deg) translateZ(${diceSize / 2}px)`,
		right: `rotateY(90deg) translateZ(${diceSize / 2}px)`,
		left: `rotateY(-90deg) translateZ(${diceSize / 2}px)`,
		top: `rotateX(90deg) translateZ(${diceSize / 2}px)`,
		bottom: `rotateX(-90deg) translateZ(${diceSize / 2}px)`,
	};
}

// function dotPositions(diceSize, dotSize, key) {
// 	let centerPosition = diceSize / 2 - dotSize / 2;
// 	let leftEdgePosition = diceSize / 4 - dotSize / 2;
// 	let rightEdgePosition = diceSize - leftEdgePosition - dotSize;
// 	return {
// 		front1: css`
// 			top: ${centerPosition}px;
// 			left: ${centerPosition}px;
// 		`,
// 		back1: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		back2: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		right1: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		right2: css`
// 			top: ${centerPosition}px;
// 			left: ${centerPosition}px;
// 		`,
// 		right3: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		left1: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		left2: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		left3: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		left4: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		top1: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		top2: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		top3: css`
// 			top: ${centerPosition}px;
// 			left: ${centerPosition}px;
// 		`,
// 		top4: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		top5: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		bottom1: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		bottom2: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${centerPosition}px;
// 		`,
// 		bottom3: css`
// 			top: ${leftEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 		bottom4: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${leftEdgePosition}px;
// 		`,
// 		bottom5: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${centerPosition}px;
// 		`,
// 		bottom6: css`
// 			top: ${rightEdgePosition}px;
// 			left: ${rightEdgePosition}px;
// 		`,
// 	};
// }

const Die = styled(motion.div)`
	width: ${({ diceSize }) => diceSize}px;
	height: ${({ diceSize }) => diceSize}px;
	transform-style: preserve-3d;
	transform: rotateX(0) rotateY(0) rotateZ(0) translateZ(1100px);
	position: absolute;
	will-change: transform;
	left: ${({ leftPos }) => leftPos};
	top: ${({ topPos }) => topPos};
`;
const Face = styled.div`
	background-color: black;
	display: block;
	position: absolute;
	width: ${({ diceSize }) => diceSize}px;
	height: ${({ diceSize }) => diceSize}px;
	background-image: ${({ six = false }) =>
		six ? `url(${diceFace6})` : `url(${diceFace})`};
	background-size: cover;
	//border: 1px solid #222222;
	margin: 0 auto;
	${({ side, diceSize, dotSize }) =>
		`transform: ${faceStyles(diceSize, dotSize)[side]}`};
`;
const FaceLight = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	will-change: opacity;
`;

// const Dot = styled.div`
// 	background: ${transparentize(0.5, "white")};
// 	display: block;
// 	position: absolute;
// 	width: ${({ dotSize }) => dotSize}px;
// 	height: ${({ dotSize }) => dotSize}px;
// 	border-radius: ${({ dotSize }) => dotSize / 2}px;
// 	${({ face, number, diceSize, dotSize }) => {
// 		return dotPositions(diceSize, dotSize)[`${face}${number}`];
// 	}};
// `;
const Svg = styled.svg`
	position: absolute;
	top: ${({ top }) => top};
	left: ${({ left }) => left};
	z-index: 5;
	transform: ${({ size }) => `translate3d(${size / 2}px, ${size / 2}px, 0)`};
	will-change: transform;
	overflow: visible;
	path {
		fill: #444444;
	}
`;

function extendPointByVector(point, vector, z) {
	let t = (z - point[2]) / -vector[2];
	let x = point[0] - vector[0] * t;
	let y = point[1] - vector[1] * t;
	return [x, y, z];
}

export default function Dice({
	progress,
	rotateXOutput,
	rotateInput = [0, 1],
	rotateYOutput,
	rotateZOutput,
	zInput,
	zOutput,
	zEasing,
	xInput = [0, 1],
	xOutput = [0, 0],
	yInput = [0, 1],
	yOutput = [0, 0],
	left,
	top,
	size = 200,
	dotSize = 30,
}) {
	let reverseLighting = [];
	const diceRef = useRef();
	//[0, 1, 0] into screen
	//[0, -1, 0] outof screen
	//[0, 0, 1] down from top
	//[0,0,-1] up from bottom
	//[1,0,0] from right
	//[-1,0,0] from left
	// vec3.normalize(reverseLighting, [-0.78, -0.5, 0.8]);
	// vec3.normalize(reverseLighting, [-0.78, -0.5, 0.5]);
	vec3.normalize(reverseLighting, [0.8, 0.7, -0.7]);
	let rotateX = useTransform(progress, rotateInput, rotateXOutput);
	let rotateY = useTransform(progress, rotateInput, rotateYOutput);
	let rotateZ = useTransform(progress, rotateInput, rotateZOutput);
	let z = useTransform(progress, zInput, zOutput, {
		ease: zEasing,
	});
	let x = useTransform(progress, xInput, xOutput);
	let y = useTransform(progress, yInput, yOutput);
	let transformMatrix = useTransform(
		[rotateX, rotateY, rotateZ, x, y, z],
		([rotateX, rotateY, rotateZ, x, y, z]) => {
			let rotationMatrix = mat4.create();
			mat4.identity(rotationMatrix);
			mat4.rotateX(rotationMatrix, rotationMatrix, (rotateX * Math.PI) / 180);
			mat4.rotateY(rotationMatrix, rotationMatrix, (rotateY * Math.PI) / 180);
			mat4.rotateZ(rotationMatrix, rotationMatrix, (rotateZ * Math.PI) / 180);
			let scaleMatrix = mat4.create();
			scaleMatrix = mat4.scale(scaleMatrix, scaleMatrix, [5, 5, 5]);

			let translateMatrix = mat4.fromTranslation([], [x + 200, y + 200, z]);

			let dest = mat4.multiply([], translateMatrix, rotationMatrix);
			dest = mat4.multiply(dest, dest, scaleMatrix);

			return dest;
		}
	);
	let normalMatrix = useTransform(transformMatrix, (matrix) => {
		let dest = mat4.create();
		mat4.invert(dest, matrix);
		mat4.transpose(dest, dest);
		return dest;
	});
	let faceLighting = useTransform(normalMatrix, (matrix) => {
		let front = [],
			back = [],
			top = [],
			bottom = [],
			right = [],
			left = [];
		let mat3Matrix = [];
		mat3.fromMat4(mat3Matrix, matrix);
		vec3.transformMat3(front, [0, 0, -1], mat3Matrix);
		vec3.transformMat3(back, [0, 0, 1], mat3Matrix);
		vec3.transformMat3(top, [0, 1, 0], mat3Matrix);
		vec3.transformMat3(bottom, [0, -1, 0], mat3Matrix);
		vec3.transformMat3(right, [-1, 0, 0], mat3Matrix);
		vec3.transformMat3(left, [1, 0, 0], mat3Matrix);
		vec3.normalize(front, front);
		vec3.normalize(back, back);
		vec3.normalize(right, right);
		vec3.normalize(left, left);
		vec3.normalize(top, top);
		vec3.normalize(bottom, bottom);
		return {
			front: Math.max(vec3.dot(front, reverseLighting), 0),
			back: Math.max(vec3.dot(back, reverseLighting), 0),
			top: Math.max(vec3.dot(top, reverseLighting), 0),
			bottom: Math.max(vec3.dot(bottom, reverseLighting), 0),
			right: Math.max(vec3.dot(right, reverseLighting), 0),
			left: Math.max(vec3.dot(left, reverseLighting), 0),
		};
	});
	let frontLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.front * 0.8
	);
	let backLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.back * 0.8
	);
	let topLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.top * 0.8
	);
	let bottomLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.bottom * 0.8
	);
	let rightLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.right * 0.8
	);
	let leftLightOpacity = useTransform(
		faceLighting,
		(lighting) => lighting.left * 0.8
	);

	let groundPoints = useTransform(
		[x, y, z, transformMatrix],
		([x, y, z, transformMatrix]) => {
			let h = size * 0.5;
			let groundPoints = [
				extendPointByVector(
					vec3.transformMat4([], [-h, -h, -h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [h, -h, -h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [-h, h, -h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [h, h, -h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [-h, -h, h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [h, -h, h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [-h, h, h], transformMatrix),
					reverseLighting,
					0
				),
				extendPointByVector(
					vec3.transformMat4([], [h, h, h], transformMatrix),
					reverseLighting,
					0
				),
			];
			return groundPoints;
		}
	);
	let path = useTransform(groundPoints, (groundPoints) => {
		let points = groundPoints.map((p) => [p[0], p[1]]);
		points = polygonHull(points);
		let p = pathHelper();
		p.moveTo(points[0][0], points[0][1]);
		for (let i = 1; i < points.length; i++) {
			p.lineTo(points[i][0], points[i][1]);
		}
		p.closePath();
		return p.toString();
	});
	let matrix3d = useTransform(transformMatrix, (m) => {
		return `matrix3d(${m[0]},${m[1]},${m[2]},${m[3]},${m[4]},${m[5]},${m[6]},${m[7]},${m[8]},${m[9]},${m[10]},${m[11]},${m[12]},${m[13]},${m[14]},${m[15]})`;
	});

	return (
		<>
			<Svg top={top} left={left} size={size}>
				<motion.path stroke={"none"} d={path} />
			</Svg>
			<Die
				style={{ transform: matrix3d }}
				ref={diceRef}
				// style={{rotateX, rotateY,rotateZ, z, y, x, scaleX: 5, scaleY: 5, scaleZ: 5}}
				leftPos={left}
				topPos={top}
				diceSize={size}>
				<Face side={"front"} diceSize={size} dotSize={dotSize}>
					<FaceLight style={{ opacity: frontLightOpacity }} />
				</Face>
				<Face side={"back"} diceSize={size} dotSize={dotSize}>
					<FaceLight style={{ opacity: backLightOpacity }} />
				</Face>
				<Face side={"right"} diceSize={size} dotSize={dotSize}>
					<FaceLight style={{ opacity: rightLightOpacity }} />
				</Face>
				<Face side={"left"} diceSize={size} dotSize={dotSize}>
					<FaceLight style={{ opacity: leftLightOpacity }} />
				</Face>
				<Face side={"top"} diceSize={size} dotSize={dotSize}>
					<FaceLight style={{ opacity: topLightOpacity }} />
				</Face>
				<Face side={"bottom"} diceSize={size} dotSize={dotSize} six={true}>
					<FaceLight style={{ opacity: bottomLightOpacity }} />
				</Face>
			</Die>
		</>
	);
}
