import health from "../images/health.png";
import education from "../images/education.png";
import separation from "../images/seperation.png";
import children from "../images/children.png";
import employment from "../images/employment.png";
import inheritance from "../images/inheritance.png";
import marriage from "../images/marriage.png";
const chance = {
	masters: {
		image: education,
		name: "Masters Degree",
		moreInfo: "education",
		prefix: "Graduation",
		title: "Well done, you graduated with a Master’s Degree!",
		description:
			"Lose 50k BUT your income growth increases by 25% if you picked a male or white Privilege Card, or by 15%, if not.",
		limit: 1,
		weight: (state, privileges, statuses, contexts) =>
			statuses.masters ? 0 : 5,
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (
				!(
					(privileges.upperMiddleClass && state.age < 40) ||
					privileges.educatedFamily
				)
			) {
				newState.wealth = newState.wealth - 50000;
			}
			if (!contexts.migrant) {
				newState.incomeGrowthMultiplier =
					newState.incomeGrowthMultiplier *
					(privileges.white || privileges.male ? 1.25 : 1.15);
			}

			newStatuses.masters = true;
			return { state: newState, statuses: newStatuses };
		},
	},
	health: {
		image: health,
		prefix: "Serious Health Condition",
		moreInfo: "health",
		name: "Serious Health Condition",
		title:
			"Your character has been diagnosed with a serious medical condition.",
		description: "Lose 40k and cost of living increases by 10%.",
		weight: (state, privileges, statuses, contexts) => {
			const baseWeight = 8;
			let weightMultiplier = 1;
			if (statuses.health) {
				weightMultiplier = weightMultiplier + 0.5 * statuses.health;
			}
			if (privileges.privateInsurance) {
				weightMultiplier = weightMultiplier * 0.5;
			}
			return baseWeight * weightMultiplier;
		},
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!(privileges.upperMiddleClass && state.age < 40)) {
				newState.wealth =
					newState.wealth - 40000 * (privileges.privateInsurance ? 0.2 : 1);
				newState.costOfLiving =
					newState.costOfLiving *
					(1 + 0.1 * (privileges.privateInsurance ? 0.2 : 1));
				newStatuses.health = newStatuses.health ? newStatuses.health + 1 : 1;
			}
			return { state: newState, statuses: newStatuses };
		},
	},
	divorce: {
		name: "Divorce",
		image: separation,
		moreInfo: "separation",
		prefix: "Separation",
		title:
			"Your character’s marriage didn’t work out. You’re getting a divorce.",
		description:
			"Your wealth is halved. Income growth is reduced by 10% and cost of living increases 10% if you're not male.",
		weight: (state, privileges, statuses, contexts) =>
			statuses.married ? 5 : 0,
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!(privileges.upperMiddleClass && state.age < 40)) {
				newState.wealth = newState.wealth / 2;
				if (!privileges.male) {
					newState.costOfLiving = newState.costOfLiving * 1.1;
					newState.incomeGrowthMultiplier =
						newState.incomeGrowthMultiplier * 0.9;
				}
			}
			newStatuses.married = false;
			newStatuses.divorced = true;
			return { state: newState, statuses: newStatuses };
		},
	},
	marriage: {
		image: marriage,
		name: "Marriage",
		prefix: "Marriage",
		title: "Your character just met the love of their life and got hitched!",
		description: "Lose 40k BUT your income growth increases by 25%.",
		weight: (state, privileges, statuses, contexts) =>
			statuses.married ? 0 : 7,
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!(privileges.upperMiddleClass && state.age < 40)) {
				newState.wealth = newState.wealth - 40000;
			}
			newState.incomeGrowthMultiplier = newState.incomeGrowthMultiplier * 1.25;
			newStatuses.married = true;
			newStatuses.divorced = false;
			return { state: newState, statuses: newStatuses };
		},
	},
	children: {
		image: children,
		prefix: "New Baby",
		moreInfo: "parenting",
		title:
			"Your character just welcomed a child. If only someone had told you how expensive it is...",
		description: "Lose 40k, and cost of living increases by 25%.",
		name: "Children",
		weight: (state, privileges, statuses, contexts) => {
			const baseWeight = 10;
			if (state.age > 50) {
				return 0;
			} else if (state.age > 40) {
				return baseWeight * 0.1;
			} else if (statuses.children > 1) {
				return baseWeight * 0.5;
			}
			return baseWeight;
		},
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!(privileges.upperMiddleClass && state.age < 40)) {
				newState.wealth = newState.wealth - 40000;
				newState.costOfLiving = newState.costOfLiving * 1.25;
			}
			newStatuses.children = newStatuses.children
				? newStatuses.children + 1
				: 1;
			return { state: newState, statuses: newStatuses };
		},
	},
	unemployment: {
		image: employment,
		prefix: "You're Fired",
		name: "Unemployment",
		moreInfo: "unemployment",
		title: "The job market is so harsh! Your character just lost their job.",
		description:
			"2 years of no income. Income growth reduced by 25% if male or white, or by 30% if not male or white.",
		weight: (state, privileges, statuses, contexts) => {
			if (privileges.networkEffect) {
				return 0;
			} else {
				return 3;
			}
		},
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!(privileges.upperMiddleClass && state.age < 40)) {
				newState.incomeGrowthMultiplier =
					newState.incomeGrowthMultiplier *
					(1 - (privileges.male || privileges.white ? 0.2 : 0.3));
				newStatuses.unemployment = 2;
			}
			return { state: newState, statuses: newStatuses };
		},
	},
	inheritance: {
		image: inheritance,
		name: "Inheritance",
		moreInfo: "inheritance",
		prefix: "Inheritance",
		title: "A rich relative just died and left a will...",
		description: "You've just inherited 1 million dollars.",
		weight: (state, privileges, statuses, contexts) => {
			return 0.5;
		},
		effects: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			newState.wealth = newState.wealth + 1000000;
			newStatuses.inheritance = newStatuses.inheritance
				? newStatuses.inheritance + 1
				: 1;

			return { state: newState, statuses: newStatuses };
		},
	},
};

export default chance;
