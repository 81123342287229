import { drawChanceCard } from "../util/game";
import chance from "./chance";

const context = {
	disabled: {
		name: "Disability",
		title: "Your character has a disability.",
		description:
			"Cost of living is now 125%. If you pick a Health Card, the income growth factor will decrease 20%.",
		initialEffect: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			if (!privileges.upperMiddleClass) {
				newState.costOfLiving = newState.costOfLiving * 1.25;
				newState.incomeGrowthMultiplier = newState.incomeGrowthMultiplier * 0.8;
				({ state: newState, statuses: newStatuses } = drawChanceCard(
					0,
					newState,
					privileges,
					newStatuses,
					contexts,
					chance.health
				));
			}
			return { state: newState, statuses: newStatuses };
		},
	},
	rural: {
		name: "Rural",
		title: "Your character lives in the countryside.",
		description: "Income is reduced by 15%",
		initialEffect: (state, privileges, statuses, contexts) => {
			let newState = { ...state };
			let newStatuses = { ...statuses };
			newState.income = newState.income * 0.85;
			return { state: newState, statuses: newStatuses };
		},
	},
	migrant: {
		name: "Migrant",
		title: "Your character is a migrant.",
		description: "The positive impacts of Education Cards are annulled.",
	},
	urban: {
		name: "Urban",
		title: "Your character lives in a city.",
		description: "No negative effects.",
	},
	native: {
		name: "Native",
		title: "Your character is a native of your country.",
		description: "No negative effects.",
	},
	ableBodied: {
		name: "No Disability",
		title: "Your character has no disability.",
		description: "No negative effects.",
	},
};

export default context;
