import chance from "../data/chance";
import allPrivileges from "../data/privileges";
import { shuffle } from "lodash";
import allContexts from "../data/context";

export function yearlyTick(years, state, privileges, statuses, contexts) {
	let newState = { ...state };
	let newStatuses = { ...statuses };
	newState.age = newState.age + 1;

	if (years % 10 === 0 && privileges.educatedFamily) {
		newState.incomeGrowthMultiplier = newState.incomeGrowthMultiplier * 1.25;
	}

	newState.income = newState.income * (1 + newState.incomeGrowthMultiplier);
	newState.costOfLiving = newState.costOfLiving * newState.inflation;
	if (!(privileges.educatedFamily && newState.age < 22)) {
		newState.wealth = newState.wealth - newState.costOfLiving;
	}
	if (
		!(privileges.educatedFamily && newState.age < 22) &&
		!statuses.unemployment
	) {
		let discretionaryIncome = newState.income - newState.costOfLiving;
		let otherExpenses = 0;
		if (discretionaryIncome > 0 && state.wealth >= 0) {
			let savings =
				newState.savingsMin +
				Math.random() * (newState.savingsMax - newState.savingsMin);
			otherExpenses = discretionaryIncome * (1 - savings);
		}
		newState.discretionaryIncome = discretionaryIncome;
		newState.additionalSpending = otherExpenses;

		newState.wealth = newState.wealth + newState.income - otherExpenses;
	}

	if (newState.wealth > 0) {
		newState.wealth = newState.wealth * newState.wealthRate;
	} else {
		newState.wealth = newState.wealth * newState.debtRate;
	}
	if (newStatuses.unemployment) {
		newStatuses.unemployment = newStatuses.unemployment - 1;
	} else {
		delete newStatuses.unemployment;
	}
	return { state: newState, statuses: newStatuses };
}
export function drawChanceCard(
	years,
	state,
	privileges,
	statuses,
	contexts,
	card
) {
	let selectedChance;
	if (card) {
		selectedChance = card;
	} else {
		let chanceKeys = Object.keys(chance);
		let brackets = [];
		let total = 0;
		for (const key of chanceKeys) {
			const weight = chance[key].weight(state, privileges, statuses, contexts);
			if (weight > 0) {
				brackets.push({
					key,
					start: total,
					end: total + weight,
					weight: weight,
				});
			}
			total += weight;
		}
		if (total < 38) {
			const diff = 38 - total;
			const smear = diff / (brackets.length - 1);
			const additionalBrackets = [];
			for (let i = 0; i < brackets.length; i++) {
				let bracket = brackets[i];
				if (bracket.key !== "inheritance") {
					additionalBrackets.push({
						key: bracket.key,
						start: total,
						end: total + smear,
						weight: smear,
					});
					total += smear;
				}
			}
			brackets.push(...additionalBrackets);
		}
		if (total < 40) {
			total = 40;
		}

		let random = Math.random() * total;
		let selectedChanceKey = brackets.find(
			(bracket) => random >= bracket.start && random < bracket.end
		);
		selectedChance = selectedChanceKey ? chance[selectedChanceKey.key] : null;
	}
	if (selectedChance) {
		return {
			...selectedChance.effects(state, privileges, statuses, contexts),
			selectedChance,
		};
	} else {
		return {
			state,
			privileges,
			statuses,
			contexts,
			selectedChance: {
				name: "Nothing Happened",
				title:
					"This phase of your life was uneventful. Maybe you need to get out more?",
				prefix: "Nothing Happened",
				description: "Your income continues to grow.",
			},
		};
	}
}

export function drawInitialState(state, privileges, statuses, contexts) {
	let privilegeKeys = Object.keys(allPrivileges);
	let shuffledPrivileges = shuffle(privilegeKeys);
	let privilege1Key = shuffledPrivileges[0];
	let privilege2Key = shuffledPrivileges[1];

	let newPrivileges;
	if (privilege1Key === "noPrivilege" || privilege2Key === "noPrivilege") {
		newPrivileges = {};
		if (privilege1Key !== "noPrivilege") {
			newPrivileges[`${privilege1Key}_disabled`] = true;
		}
		newPrivileges.noPrivilege = true;
		if (privilege2Key !== "noPrivilege") {
			newPrivileges[`${privilege2Key}_disabled`] = true;
		}
	} else {
		newPrivileges = {
			[privilege1Key]: true,
			[privilege2Key]: true,
		};
	}

	for (const key of Object.keys(newPrivileges)) {
		let privilege = allPrivileges[key];
		if (privilege && privilege.initialEffect) {
			({ state, statuses } = privilege.initialEffect(
				state,
				privileges,
				statuses,
				contexts
			));
		}
	}

	let contextsKeys = Object.keys(allContexts);
	let shuffledContexts = shuffle(contextsKeys);
	let selectedContext = allContexts[shuffledContexts[0]];
	let newContexts = { [shuffledContexts[0]]: true };
	if (selectedContext.initialEffect) {
		({ state, statuses } = selectedContext.initialEffect(
			state,
			privileges,
			statuses,
			contexts
		));
	}

	return { privileges: newPrivileges, contexts: newContexts, state, statuses };
}

export function generateGame(initialState) {
	let state = { ...initialState };
	let privileges = {};
	let statuses = {};
	let contexts = {};
	let chanceCards = [];
	let game = [];
	let selectedChance;
	({ state, privileges, statuses, contexts } = drawInitialState(
		state,
		privileges,
		statuses,
		contexts
	));

	for (let year = 0; year <= 65 - 18; year++) {
		if (year > 0) {
			({ state, statuses } = yearlyTick(
				year,
				state,
				privileges,
				statuses,
				contexts
			));
			if ((year - 2) % state.chanceCardInterval === 0 && year > 2) {
				({ state, statuses, selectedChance } = drawChanceCard(
					year,
					state,
					privileges,
					statuses,
					contexts
				));
				chanceCards.push(selectedChance);
			}
		}

		game.push(state);
	}
	return { game, privileges, contexts, chanceCards };
}

export function generateGames(initialState, count = 10) {
	let games = [];
	for (let i = 0; i < count; i++) {
		let state = { ...initialState };
		let privileges = {};
		let statuses = {};
		let contexts = {};
		let history = [];
		let selectedChance;
		({ state, privileges, statuses, contexts } = drawInitialState(
			state,
			privileges,
			statuses,
			contexts
		));

		for (let year = 0; year <= 65 - 18; year++) {
			if (year > 0) {
				({ state, statuses } = yearlyTick(
					year,
					state,
					privileges,
					statuses,
					contexts
				));
				if ((year - 2) % state.chanceCardInterval === 0 && year > 2) {
					({ state, statuses, selectedChance } = drawChanceCard(
						year,
						state,
						privileges,
						statuses,
						contexts
					));
				}
			}
			history.push({ state, selectedChance });
		}
		games.push(history);
	}
	return games;
}

// function updateState(years, state, privileges, statuses, contexts) {
//     if(years > 0 && years <= (65 - 18)) {
// 				let selectedChance;
//         ({state, statuses} = yearlyTick(years, state, privileges, statuses, contexts));
//         if(years % state.chanceCardInterval === 0 && years > 0) {
//             ({state, statuses, selectedChance} = drawChanceCard(years, state, privileges, statuses, contexts));
//         }
//         playerState.set(state);
//         playerStatuses.set(statuses);
//         playerHistory.update(history => {
//             let newHistory = [...history];
//             newHistory.push(state);
//             return newHistory
//         })
//
//     }
// }
