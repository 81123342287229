import React, { memo } from "react";
import styled from "@emotion/styled";
import { format } from "d3-format";
import housePrices from "./data/house-prices";
import { wealthClass, wealthLevel } from "./util/wealthClass";
import { pSmall, fontRoslindaleDisplay } from "../../../../styles/typography";
import { spacing } from "../../../../styles/mixins";
import privileges from "./data/privileges";
import contextCards from "./data/context";
import { transparentize } from "polished";
import { colours } from "../../../../styles/colours";
const Container = styled.div`
	width: 300px;
`;
const Title = styled.h4`
	font-size: 25px;
	${fontRoslindaleDisplay};
	margin-bottom: 14px;
`;
const Wealth = styled.span`
	color: ${({ positive = true }) => (positive ? "green" : "red")};
	font-weight: 600;
`;
const P = styled.p`
	strong {
		font-weight: 600;
	}
`;
const Content = styled.div`
	margin-bottom: 35px;
`;

const Graph = styled.div`
	display: flex;
	height: 200px;
	width: 100%;
	align-items: flex-end;
	margin-bottom: 50px;
`;
const Bar = styled.div`
	flex-grow: 1;
	height: ${({ height }) => height}px;
	background-color: #161616;
	opacity: ${({ active = false }) => (active ? 1 : 0.3)};
	margin-right: 6px;
	&:last-child {
		margin-right: 0;
	}
`;
const PlayAgainButton = styled.button`
	${pSmall}
	${spacing("padding", "s")};
	${spacing("marginTop", "s")};
	border: 1px solid ${transparentize(0.7, "black")};
	border-radius: 500px;
	display: block;
	width: 100%;
	color: black;
	transition: border-color 400ms ease-in-out;

	&:hover {
		border-color: black;
	}

	&:focus {
		outline: none;
		border-color: ${colours.highlight};
	}
`;

const formatWealth = format(",.0f");
export default memo(function Retire({ state, game, startOver }) {
	let retireYears = state.wealth / state.costOfLiving;
	let houseLocation = housePrices.find((p) => p.cost * 0.2 < state.wealth);
	let classNumber = wealthLevel(retireYears);
	let privilegeCards = Object.keys(game.privileges)
		.map((p) => privileges[p])
		.filter((p) => !!p);
	let contexts = Object.keys(game.contexts).map((p) => contextCards[p]);
	return (
		<Container>
			<Content>
				<Title>
					You’re 65!
					<br /> Is it time to retire?
				</Title>
				<P>
					You started life with a hand that included:{" "}
					<strong>
						{[...privilegeCards, ...contexts].map((c) => c.name).join("; ")}
					</strong>
					. As you lived your life, you drew Chance Cards:{" "}
					<strong>
						{game.chanceCards
							.map((c) => c.prefix)
							.filter((n) => n !== "No Event")
							.join(", ")}
					</strong>
					.
				</P>
			</Content>
			<Content>
				<P>
					You’ve earned wealth and incurred costs, but can you retire? How long
					can you live a good life in your retirement? Your lifetime wealth is{" "}
					<Wealth positive={state.wealth >= 0}>
						{state.wealth < 0 ? "-" : ""}${formatWealth(Math.abs(state.wealth))}
					</Wealth>
					.
					{retireYears > 0 ? (
						<>
							{" "}
							You can afford{" "}
							<strong>{Math.round(retireYears * 100) / 100}</strong> years of
							retirement
							{houseLocation ? (
								<>
									{" "}
									in <strong>{houseLocation.country}</strong>.
								</>
							) : (
								` but have nowhere to retire to.`
							)}
						</>
					) : (
						" You cannot afford to retire."
					)}
				</P>
			</Content>
			<Content>
				<P>
					The final economic class you have ended up in is the{" "}
					<strong>{wealthClass(retireYears)}</strong> class.
				</P>
			</Content>
			<Graph>
				<Bar height={50} active={classNumber === 1} />
				<Bar height={80} active={classNumber === 2} />
				<Bar height={110} active={classNumber === 3} />
				<Bar height={140} active={classNumber === 4} />
				<Bar height={170} active={classNumber === 5} />
				<Bar height={200} active={classNumber === 6} />
			</Graph>
			<Content>
				<P>
					One life of chance can go either way. A pattern emerges when we
					simulate multiple lives on the wealth chart. Each of these lives
					assumes a start point from the same set of Starting Conditions, and a
					set of life events from the same set of Chance Cards.
				</P>
			</Content>
			<Content>
				<P>
					In this model, just as in reality, many people struggle to earn their
					way to an easy retirement and inheritance for their children, despite
					a life of earning. The hand they’re dealt at birth can have a much
					greater influence on their chances for social mobility than all but
					the most dramatic of life events.
				</P>
			</Content>
			<PlayAgainButton onClick={startOver}>PLAY AGAIN</PlayAgainButton>
		</Container>
	);
});
