import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTransform, useViewportScroll } from "framer-motion";
import Game from "./Game";
import { useGetResizer } from "../../../../contexts/Resizer";
import {
	FIVE_YEARS,
	GRAPH_HEIGHT,
	INTRODUCTION_SCREENS,
	MAX_AGE,
	MIN_AGE,
} from "./constants";
import { clamp } from "../../../../util/math";
import { AgeContext } from "./AgeContext";
import GameIntro from "./GameIntro";
import { generateGame, generateGames } from "./util/game";
import { format } from "d3-format";
const Container = styled.div`
	height: ${({ totalHeight }) => totalHeight}vh;
	position: relative;
	margin-top: -25vh;
`;
const Inner = styled.div`
	position: sticky;
	top: 0;
	height: 100vh;
	width: 100%;
`;
const formatWealth = format(",.0f");
export default function GameContainer(props) {
	const {
		resetGame,
		title,
		subtitle,
		introduction,
		about,
		keyId,
		initialState,
		setResetGame,
		setGame,
		setOtherGames,
	} = props;
	const area = useGetResizer();
	const containerRef = useRef();
	const topResetRef = useRef();
	const [containerBounds, setContainerBounds] = useState({
		top: 0,
		bottom: 100,
		width: 1440,
		height: 830,
	});
	const [currentAge, setCurrentAge] = useState(18);
	const viewportScroll = useViewportScroll();
	const [retireHeight, setRetireHeight] = useState(1395.69);
	useEffect(() => {
		let element = containerRef.current;
		let scrollY = window.scrollY;
		let rect = element.getBoundingClientRect();
		setContainerBounds({
			top: rect.top + scrollY - window.innerHeight,
			bottom: rect.bottom + scrollY - window.innerHeight,
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}, [area]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (resetGame) {
						setResetGame(false);
						let game = generateGame(initialState);
						setGame(game);
						let otherGames = generateGames(initialState, 50);
						setOtherGames(otherGames);
					}
				}
			});
		});
		observer.observe(topResetRef.current);
		return () => {
			observer.disconnect();
		};
	}, [resetGame, setGame, setResetGame, setOtherGames, initialState]);

	let graphInViewports = GRAPH_HEIGHT / containerBounds.height;
	let additionalScreens =
		Math.max(retireHeight - FIVE_YEARS, 0) / containerBounds.height;
	let totalHeight =
		INTRODUCTION_SCREENS * 100 +
		graphInViewports * 100 +
		additionalScreens * 100;
	const progress = useTransform(
		viewportScroll.scrollY,
		[containerBounds.top, containerBounds.bottom],
		[0, totalHeight]
	);
	const age = useTransform(
		progress,
		[
			INTRODUCTION_SCREENS * 100,
			INTRODUCTION_SCREENS * 100 + graphInViewports * 100,
		],
		[18, 65],
		{ clamp: false }
	);

	useEffect(() =>
		age.onChange((val) =>
			setCurrentAge(clamp(Math.floor(val), MIN_AGE, MAX_AGE))
		)
	);

	return (
		<AgeContext.Provider value={currentAge}>
			<div>
				<GameIntro
					{...{
						title,
						subtitle,
						introduction,
						about,
						keyId,
						initialState,
						formatWealth,
						topResetRef,
					}}
				/>
				<Container totalHeight={totalHeight} ref={containerRef}>
					<Inner>
						<Game
							progress={progress}
							age={age}
							containerBounds={containerBounds}
							setRetireHeight={setRetireHeight}
							retireHeight={retireHeight}
							{...props}
						/>
					</Inner>
				</Container>
			</div>
		</AgeContext.Provider>
	);
}
