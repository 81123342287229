import React, { useRef, useState, useEffect } from "react";
import PicketTitle from "../../../Structure/PicketTitle";
import styled from "@emotion/styled";
import { spacing } from "../../../../styles/mixins";
import { useGetResizer } from "../../../../contexts/Resizer";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { easing } from "../../../../util/math";

const StickyContainer = styled(motion.div)`
	position: sticky;
	top: ${({ top }) => `${top}px`};
	height: ${({ height }) => `${height}px`};
	${spacing("marginTop", "xl")};
	z-index: 10;
`;

const Wrapper = styled.div`
	overflow: hidden;
	position: absolute;
	top: ${({ top }) => `${top}px`};
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	transform-style: preserve-3d;
	perspective: 1200px;
	perspective-origin: center calc(100% - 100px);
	transform-style: preserve-3d;
	transform-origin: bottom center;
`;

const Ref = styled.div`
	transform-style: preserve-3d;
`;

const TitleEl = styled(motion.div)`
	will-change: transform;
`;

export default function Title({ title, containerTop }) {
	const area = useGetResizer();
	const ref = useRef();
	const [height, setHeight] = useState(0);
	const [windowHeight, setWindowHeight] = useState(768);

	const { scrollY } = useViewportScroll();

	const z = useTransform(
		scrollY,
		[containerTop + height, containerTop + height + windowHeight * 0.6],
		[0, 1200],
		{ ease: easing.inQuad }
	);

	const opacity = useTransform(
		scrollY,
		[
			containerTop + height + windowHeight * 0.3,
			containerTop + height + windowHeight * 0.6,
		],
		[1, 0]
	);

	const visibility = useTransform(opacity, (opacity) => {
		if (opacity > 0) {
			return "visible";
		} else {
			return "hidden";
		}
	});

	useEffect(() => {
		const h = ref.current?.clientHeight;
		setHeight(h);
	}, [area]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<StickyContainer top={(windowHeight - height) * 0.5} height={height}>
			<Wrapper top={(windowHeight - height) * -0.5}>
				<Ref ref={ref}>
					<TitleEl style={{ z, opacity, visibility }}>
						<PicketTitle content={title} />
					</TitleEl>
				</Ref>
			</Wrapper>
		</StickyContainer>
	);
}
