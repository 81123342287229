import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import GameContainer from "./LifeEvents/GameContainer";
import { DEFAULT_STATE } from "./LifeEvents/util/constants";
import { generateGame, generateGames } from "./LifeEvents/util/game";
import { useDefinitionModal } from "../../../contexts/DefinitionModalContext";
import { useInView } from "react-intersection-observer";
import { useGetResizer } from "../../../contexts/Resizer";
import GameMobile from "./LifeEvents/GameMobile";

let Container = styled.div`
	position: relative;
`;

export default function LifeEvents({ moreInformation, ...props }) {
	const containerRef = useRef();
	const area = useGetResizer();
	const [mobile, setMobile] = useState(false);
	const [initialState, setInitialState] = useState(DEFAULT_STATE);
	const [game, setGame] = useState(null);
	const [otherGames, setOtherGames] = useState(null);
	const { setLifeEventsContent } = useDefinitionModal();
	const [resetGame, setResetGame] = useState(false);
	const { inView: bottomInView, ref: bottomResetRef } = useInView();
	useEffect(() => {
		if (window.innerWidth < 1150) {
			setMobile(true);
		} else {
			setMobile(false);
		}
	}, [area]);
	useEffect(() => {
		setLifeEventsContent(moreInformation);
	}, [moreInformation, setLifeEventsContent]);

	useEffect(() => {
		let game = generateGame(initialState);
		setGame(game);
		let otherGames = generateGames(initialState, 50);
		setOtherGames(otherGames);
	}, [initialState]);

	useEffect(() => {
		if (bottomInView) {
			setResetGame(true);
		}
	}, [bottomInView, setResetGame]);
	return (
		<Container ref={containerRef}>
			{!mobile ? (
				<GameContainer
					{...props}
					{...{
						initialState,
						setInitialState,
						game,
						setGame,
						otherGames,
						setOtherGames,
						resetGame,
						setResetGame,
					}}
				/>
			) : (
				<GameMobile
					{...{
						initialState,
						setInitialState,
						game,
						setGame,
						otherGames,
						setOtherGames,
						resetGame,
						setResetGame,
					}}
					{...props}
				/>
			)}
			<div ref={bottomResetRef} />
		</Container>
	);
}
