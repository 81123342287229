import styled from "@emotion/styled";
import { fontSurt } from "../../../../styles/typography";
import { colours } from "../../../../styles/colours";

const ClickForMoreInfo = styled.div`
	${fontSurt};
	font-size: 10px;
	opacity: 0.7;
	position: relative;
	padding-left: 18px;
	transition: color 400ms ease-in-out, opacity 400ms ease-in-out;

	&:after {
		text-align: center;
		content: "?";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		border: 1px solid black;
		border-radius: 50%;
		font-size: 9px;
		line-height: 12px;
		width: 13px;
		height: 13px;
		transition: border-color 400ms ease-in-out;
	}

	&:hover {
		color: ${colours.highlight};
		opacity: 1;

		&:after {
			border-color: ${colours.highlight};
		}
	}
`;

export default ClickForMoreInfo;
