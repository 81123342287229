import React from "react";
import styled from "@emotion/styled";

import { spacing } from "../../../styles/mixins";

const Visualisation = styled.div`
	${spacing("marginTop", "m")};
	background-color: #cccccc;
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export default function RaceGraph() {
	return (
		<Visualisation>
			<p>Race Graph Visualisation</p>
		</Visualisation>
	);
}
