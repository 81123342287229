import React from "react";
import styled from "@emotion/styled";
import { motion, useTransform, useSpring } from "framer-motion";
import { easing } from "../../../../util/math";
import dominoFaceImage from "../../../../images/domino-face.png";

function faceStyles(diceSize, dotSize, face) {
	return {
		front: `translateZ(${(diceSize * 0.33) / 2}px)`,
		back: `rotateX(-180deg) translateZ(${(diceSize * 0.33) / 2}px)`,
		right: `rotateY(90deg) translateZ(${diceSize / 2}px)`,
		left: `rotateY(-90deg) translateZ(${diceSize / 2}px)`,
		top: `rotateX(90deg) translateZ(${(diceSize * 2) / 2}px)`,
		bottom: `rotateX(-90deg) translateZ(${(diceSize * 2) / 2}px)`,
	};
}

const DominoShape = styled(motion.div)`
	width: ${({ diceSize }) => diceSize}px;
	height: ${({ diceSize }) => diceSize * 2}px;
	transform-origin: bottom center;
	transform-style: preserve-3d;
	transform: rotateX(0) rotateY(0) rotateZ(0) translateZ(1100px);
	position: absolute;
	top: ${({ top }) => top};
	left: ${({ left }) => left};
	display: flex;
	align-items: center;
	justify-content: center;
`;
const DominoRotationX = styled(motion.div)`
	transform-origin: bottom center;
	transform-style: preserve-3d;
	transform: rotateX(0) rotateY(0) rotateZ(0) translateZ(1100px);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Face = styled.div`
	background: #141414;
	background-image: url(${dominoFaceImage});
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	display: block;
	position: absolute;
	width: ${({ diceSize }) => diceSize}px;
	height: ${({ diceSize }) => diceSize * 2}px;
	margin: 0 auto;
	${({ diceSize, side }) => `transform: ${faceStyles(diceSize)[side]}`};
`;
const ShortEdge = styled.div`
	background: #0a0a0a;
	display: block;
	position: absolute;
	width: ${({ diceSize }) => diceSize}px;
	height: ${({ diceSize }) => diceSize * 0.33}px;
	margin: 0 auto;
	${({ diceSize, side }) => `transform: ${faceStyles(diceSize)[side]}`};
`;
const LongEdge = styled.div`
	background: #0a0a0a;
	display: block;
	position: absolute;
	width: ${({ diceSize }) => diceSize * 0.33}px;
	height: ${({ diceSize }) => diceSize * 2}px;
	margin: 0 auto;
	${({ diceSize, side }) => `transform: ${faceStyles(diceSize)[side]}`};
`;

export default function Domino({
	progress,
	rotateXOutput,
	rotateInput = [0, 1],
	rotateYOutput,
	rotateZOutput,
	zInput,
	zOutput,
	xInput = [0, 1],
	xOutput = [0, 0],
	yInput = [0, 1],
	yOutput = [0, 0],
	left,
	top,
	size = 200,
}) {
	let rotateX = useSpring(
		useTransform(progress, rotateInput, rotateXOutput, {
			easing: easing.inOutCubic,
		}),
		{
			stiffness: 800,
			damping: 50,
		}
	);
	let rotateY = useTransform(progress, rotateInput, rotateYOutput);
	let rotateZ = useTransform(progress, rotateInput, rotateZOutput);
	let z = useTransform(progress, zInput, zOutput);
	let x = useTransform(progress, xInput, xOutput);
	let y = useTransform(progress, yInput, yOutput);
	return (
		<DominoShape
			style={{ z, y, x, rotateZ, rotateY }}
			top={top}
			left={left}
			diceSize={size}>
			<DominoRotationX style={{ rotateX }}>
				<Face side={"front"} diceSize={size} />
				<Face side={"back"} diceSize={size} />
				<LongEdge side={"right"} diceSize={size} />
				<LongEdge side={"left"} diceSize={size} />
				<ShortEdge side={"top"} diceSize={size} />
				<ShortEdge side={"bottom"} diceSize={size} />
			</DominoRotationX>
		</DominoShape>
	);
}
