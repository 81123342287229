import React, { memo } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { fontSize, grid, spacing, screen } from "../../../../styles/mixins";
import pStandard, {
	fontRoslindaleDisplay,
	h2,
	meta,
	pLead,
} from "../../../../styles/typography";
import BaseStartingConditions from "./BaseStartingConditions";
import { useUIState } from "../../../../contexts/UIStore";
import { observer } from "mobx-react-lite";
const TitleContainer = styled(motion.div)`
	width: 100%;
	${grid};
`;
const Title = styled.h2`
	${h2};
	text-align: center;
	grid-column: 4 / -4;
	${spacing("marginBottom", "l")};
	span {
		${fontRoslindaleDisplay};
		font-style: italic;
		text-transform: lowercase;
		font-weight: 500;
		font-size: 1.02em;
	}
`;
const Meta = styled.span`
	${meta};
	text-transform: uppercase;
	font-weight: 600;
	grid-column: 4 / -4;
	text-align: center;
	${spacing("marginBottom", "l")};
	span {
		${fontRoslindaleDisplay};
		font-style: italic;
		text-transform: lowercase;
		font-weight: 400;
		font-size: 1.02em;
	}
`;
const IntroPara = styled(motion.div)`
	${pLead};
	grid-column: 4 / -4;
	${spacing("marginBottom", "l")};
	letter-spacing: -0.01em;
	@media ${screen("lg")} {
		grid-column: 9 / -9;
	}
}
`;

const About = styled.div`
	grid-column: 4 / -4;
	${fontSize(0)};
	@media ${screen("md")} {
		grid-column: 4 / 25;
	}
	@media ${screen("lg")} {
		grid-column: 9 / 25;
	}
`;
const AboutContent = styled.div`
	p {
		${pStandard};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
const SmallTitle = styled.h5`
	${spacing("marginBottom", "m")};
	font-weight: 600;
`;

const GameIntro = observer(
	(
		{
			title,
			subtitle,
			introduction,
			about,
			keyId,
			initialState,
			formatWealth,
			className,
			topResetRef,
			style,
		},
		ref
	) => {
		const uiState = useUIState();
		return (
			<TitleContainer style={style} className={className} ref={ref}>
				<Meta>
					{subtitle} <span>of</span> 08
				</Meta>
				<Title
					ref={topResetRef}
					dangerouslySetInnerHTML={{ __html: title }}
					id={keyId}
					style={{ scrollMarginTop: `${uiState.navHeight + 20}px` }}
				/>
				<IntroPara dangerouslySetInnerHTML={{ __html: introduction }} />
				<About>
					<SmallTitle>ABOUT THIS SIMULATION</SmallTitle>
					<AboutContent dangerouslySetInnerHTML={{ __html: about }} />
				</About>
				<BaseStartingConditions
					initialState={initialState}
					formatWealth={formatWealth}
				/>
			</TitleContainer>
		);
	}
);
export default memo(GameIntro);
